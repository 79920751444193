import { createSlice } from "@reduxjs/toolkit";
import { getProductById, getProductList,rateToProducts } from "../actions";

const initialState = {
  data: [],
  product: null,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const productSlice = createSlice({
  name: "products",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getProductList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data;
      })
      .addCase(getProductList.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      }).addCase(getProductById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.product = payload;
      })
      .addCase(rateToProducts.pending, (state) => {  
        state.isLoading = true;
      })
      .addCase(rateToProducts.fulfilled, (state) => {  
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(rateToProducts.rejected, (state, { payload }) => {  
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
  },
});

export default productSlice.reducer;
