export let BackSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M1.31098 15.2982C1.352 15.4447 1.43989 15.6381 1.50434 15.7377C1.57465 15.8314 3.09223 17.3607 4.87348 19.1361C8.40668 22.6635 8.25434 22.5346 8.91059 22.4877C9.74262 22.4291 10.2582 21.5502 9.92426 20.7533C9.86567 20.6068 9.12739 19.8275 7.68598 18.3861L5.54145 16.2299H16.6274C26.518 16.2299 27.7426 16.2181 27.936 16.1361C28.5747 15.8724 28.8793 15.1224 28.5981 14.5014C28.4868 14.2494 28.1411 13.9154 27.9067 13.8217C27.8188 13.7924 23.3891 13.7689 16.6625 13.7689C10.5571 13.7689 5.56489 13.7514 5.56489 13.7279C5.56489 13.7045 6.52582 12.7201 7.6977 11.5424C8.93989 10.306 9.86567 9.32752 9.90082 9.22791C10.0063 8.94666 9.98285 8.47791 9.84809 8.2201C9.63129 7.79237 9.18012 7.4994 8.73481 7.4994C8.24848 7.4994 8.27192 7.47596 4.81489 10.9213C2.99848 12.7318 1.46332 14.3021 1.41059 14.4135C1.2641 14.7006 1.22895 14.9818 1.31098 15.2982Z"
      fill="#20320D"
    />
  </svg>
);
