import requests from "../Axios/Instance";

const BannerService = {
    getBanners: async (data) => {
    return requests.get(`/Banner/getBanners?page=${data.page}&pageSize=${data.pageSize}&type=${data.type}`);
    
  },
  getBannersById: async (id) => {
    return requests.get(`/Banner/getBanner?_id=${id}`);
  },
  getAds: async () => {
    return requests.get(`/ads/getAds`);
  },
}; 

export default BannerService;
