//------------------------------- Sponser logo Images -------------------------------------

import one from "../Assets/Images/Svg/ecofreindly.svg";
import two from "../Assets/Images/Svg/healthy.svg";
import three from "../Assets/Images/Svg/natural.svg";
import four from "../Assets/Images/Svg/naturalbeauty.svg";
import five from "../Assets/Images/Svg/hunreadpercent.svg";
import six from "../Assets/Images/Svg/organic.svg";

export const appStrings = {
  welcome_message: "Welcome to Lushful",
  welcome_store: "Welcome to our store",
  mainTitle: "Feel to Difference in Test and Freshness",
  brief_lushful:
    "Healthy, chemical-free food from living soil delivered weekly straight to your doorstep",
  order_again: "Repeat Order",
  shop_now: "Shop now",
  select_category: "Select Category",
  Search_here: "Search here...",
  fresh_from_our_farm: "FRESH FROM OUR FARM",
  popular_Categories: "Popular Categories",
  our_baskets: "OUR BASKETS",
  our_basket_joy: "Our Basket of Joy",
  product_quality: "Product Quality",
  highest_quality: "Highest Quality",
  most_selling_item: "Most selling items",
  Vegetables: "Vegetables",
  fruits: "Fruits",
  greens: "Greens",
  window_our_farm: "Window to our Farms",
  window_our_farm_desc:
    "Apparently we had reached a great height in the atmosphere, for the sky was a",
  testimonial: "Testimonial",
  client_says: "What our Clients Says",
  subscribe_now: "Subscribe now",
  enter_mail_address: "Enter Email address",

  //   Auth
  login: "Login",
  loginText: "Enter your mobile number to proceed",
  loginLabel: "10 digit mobile number",
  continue: "continue",
  otpTitle: "Verify Details",
  otpDesc: "OTP sent to",
  resendDesc: "Didn’t receive the OTP? Resend in ",
  enter_otp: "Enter OTP",
  resend: "Resend",
  signupTitle: "Sign Up",
  signupDesc: "Please enter your details below to continue",
  name: "name",
  emailId: "Email ID",
  mobileNo: "Enter moBile No",
  Signup: "Sign Up",
  loginIn: "Log In",

  // profile
  my_profile: "My Profile",
  Total_Order: "Total Order",
  Total_Delivered: "Total Delivered",
  Pending_orders: "Pending Orders ",
  myorders: "My Orders",
  Addresses: "Addresses",
  personal_Information: "Personal Information",
  full_Name: "Full Name",
  email_ID: "Email ID",
  mobile_Number: "Mobile Number",
  Update: "Update",
  order_Id: "Order Id",
  ordered_on: "Ordered on",
  Return_Order: "Return Order",
  Cancel_Order: "Cancel Order",
  Order_Details: "Order Details",
  view_all: "View Details",
  Delivered_Date: "Delivered Date",
  billing_Address: "Billing_Address",
  phone: "Phone",
  payment_Method: "Payment Method",
  subtotal: "Subtotal",
  discount: "Discount",
  shipping: "Shipping",
  total: "Total",
  order_Confirmed: "Order Confirmed",
  Shipped: "Shipped",
  Out_For_Delivery: "Out For Delivery",
  Delivered: "Delivered",
  Account_Management: "Account Management",
  My_Profile: "My Profile",
  My_Orders: "My Orders",
  Saved_Cards: "Saved Cards",
  Membership: "Membership",
  Referal_Code: "My Referrals",
  Log_Out: "Log Out",
  Verify_mobile_number: "Verify mobile number",
  Are_you_sure: "Are you sure?",
  yes: "Yes",
  no: "No",
  address_empty: "Address is not added yet",
  AddNewAddress: "Add new address",
  Label: "Label",
  First_Name: "First Name",
  Last_Name: "Last Name",
  Address_1: "Address",
  Address_2: "Address 2",
  City: "City",
  Country_Region: "Country/Region",
  FlatNo: "Door/ Flat No",
  Postal_Code: "Postal/Zip Code",
  Phone: "phone",
  set_as_delivery_address: "Set as default shipping address",
  Cancel: "Cancel",
  Save: "Save",
  Default_Shipping_Address: "Default Shipping Address",
  My_Referrals: "My Referrals",
  refrel_heading: "Share & get  ₹50 off",
  refreela_desc:
    "Invite Your friends with your referral code you'll receive your offer when they make their first purchase",
  Invite_and_Earn: "Invite and Earn",

  // cart
  Your_cart_empty: "Your cart is currently empty",
  retrun_shop: "Return to shop",
  coupen_code: "Coupon Code",
  View_more_coupons: "View more coupons",

  // chckout page

  billing_deatail: "Billing Details",
  account: "Account",
  shipping_address: "Shipping Address",
  billing_address: "Billing Address",
  newShiipingAddress: "New shipping Address",
  useDiffrentBillingAddress: "Use Different Billing Address",

  // feedback modal
  rate_this_product: "Rate This Product",
  rating: "Ratings",
  describeUrExperiance: "Describe your experience",
  Description: "Description",
  ratingAndReview: "Ratings & Reviews",
  reviewQue:
    "Want to rate this product? You can rate or review this product only after purchasing",
  faqHeading: "Frequently Asked Questions",
  faqDes: "You can ask me anything you want to know",
};

// export const categoriesData = [
//   {
//     id: 2,
//     categoryImage: fruit,
//     title: "Fruits",
//   },
//   {
//     id: 3,
//     categoryImage: Vegetables,
//     title: "Vegetables",
//   },
//   {
//     id: 4,
//     categoryImage: green,
//     title: "Greens",
//   },
//   {
//     id: 5,
//     categoryImage: salad,
//     title: "Salads",
//   },
//   {
//     id: 6,
//     categoryImage: staples,
//     title: "Staples",
//   },
//   {
//     id: 7,
//     categoryImage: basket,
//     title: "Basket",
//   },
// ];

// export const productData = [
//   {
//     id: 1,
//     ratio: "-27%",
//     title: "Apple",
//     desc: "",
//     rating: "4.0",
//     price: 500,
//   },
//   {
//     id: 1,
//     ratio: "-27%",
//     Seasonal:"Seasonal",
//     title: "Green Apple",
//     desc: "",
//     rating: "4.0",
//     price: 500,
//   },
//   {
//     id: 1,
//     ratio: "-27%",
//     title: "Banana",
//     desc: "",
//     rating: "4.0",
//     price: 500,
//   },
//   {
//     id: 1,
//     ratio: "-27%",
//     Seasonal:"Seasonal",
//     title: "Orange",
//     desc: "",
//     rating: "4.0",
//     price: 500,
//   },
// ];


// export const basketJoysData = [
//   {
//     id: 1,
//     ratio: "-27%",
//     title: "Combo Organic Basket Subscription",
//     product_name:"Apple",
//     desc: "Fruits + Vegetables for 2 people",
//     rating: "4.0",
//     price: 500,
//   },
//   {
//     id: 1,
//     ratio: "-27%",
//     Seasonal:"Seasonal",
//     title: "Combo Organic Basket Subscription",
//     product_name:"Apple",
//     desc: "Fruits + Vegetables for 2 people",
//     rating: "4.0",
//     price: 500,
//   },
//   {
//     id: 1,
//     ratio: "-27%",
//     title: "Combo Organic Basket Subscription",
//     product_name:"Apple",
//     desc: "Fruits + Vegetables for 2 people",
//     rating: "4.0",
//     price: 500,
//   },
//   {
//     id: 1,
//     ratio: "-27%",
//     Seasonal:"Seasonal",
//     title: "Combo Organic Basket Subscription",
//     product_name:"Apple",
//     desc: "Fruits + Vegetables for 2 people",
//     rating: "4.0",
//     price: 500,
//   },
// ];

export const sponsorLogos = [one, two, three, four, five, six];

export const gradientBorder = `linear-gradient(to top, rgba(147, 147, 147, 0.12), rgba(212, 212, 212, 1)) 1 `;
