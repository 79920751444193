import { createSlice } from "@reduxjs/toolkit";
import { getAllBlogCategory } from "../actions";

const initialState = {
    blogCategory: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
};

export const BlogSlice = createSlice({
    name: "blogCategory",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllBlogCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllBlogCategory.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.blogCategory = payload;
            })
            .addCase(getAllBlogCategory.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.errorMessage = payload;
            });
    },
});

export default BlogSlice.reducer;
