import { createSlice } from "@reduxjs/toolkit";
import { getBasketById, getBasketList, createBasket, rateToBaskets, deleteBasket } from "../actions";

const initialState = {
  data: [],
  basketDetail: null,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const basketSlice = createSlice({
  name: "baskets",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBasketList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBasketList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload;
      })
      .addCase(getBasketList.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.data = []
        state.errorMessage = payload;
      })
      .addCase(getBasketById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.basketDetail = payload;
      })
      .addCase(deleteBasket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBasket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (Array.isArray(state.data)) {
          state.data = state.data.filter((basket) => basket._id !== action.payload.id.toString());
        }
      })
      .addCase(deleteBasket.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
  },
});

export default basketSlice.reducer;
