import React, { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography, useTheme } from '@mui/material';
import GreenUnderline from '../Assets/Images/other/greenUnderline.png';
import OrangeUnderline from '../Assets/Images/other/orangeUnderline.png';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  OrangeUnderLineImage: {
    height: 6,
    maxWidth: '140px',
    position: 'absolute',
    bottom: '-5px',
    left: '-12px',
  },
  GreenUnderLineImage: {
    height: 6,
    maxWidth: '200px',
    position: 'absolute',
    bottom: '-5px',
    left: '-8px',
  },
}));

const StyledTitle = ({ title, isGreen, notUnderLine }) => {
  const classes = useStyles();
  const theme = useTheme();

  const underlineImage = useMemo(() => {
    return isGreen ? GreenUnderline : OrangeUnderline;
  }, [isGreen]);

  return (
    <Box className={classes.root}>
      <Typography
        
        sx={{
          fontWeight: 800,color: isGreen ? theme.palette?.primary.dark : theme.palette.customizePrimary.main, fontSize:"25px"
        }}
      >
        {title}
      </Typography>
      {!notUnderLine && <img className={!isGreen ? classes.GreenUnderLineImage :classes.OrangeUnderLineImage } src={underlineImage}  alt='underlineSvg'/>}
    </Box>
  );
};

export default React.memo(StyledTitle);
