import { createSlice } from "@reduxjs/toolkit";
import { getAllAboutUs, getAboutUsById } from "../actions";

const initialState = {
  aboutUs: [],
  aboutUsDetail: null,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const AboutUsSlice = createSlice({
  name: "aboutUs",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllAboutUs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAboutUs.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.aboutUs = payload;
      })
      .addCase(getAllAboutUs.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
      .addCase(getAboutUsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAboutUsById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.aboutUsDetail = payload;
      });
  },
});

export default AboutUsSlice.reducer;
