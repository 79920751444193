import { createSlice } from '@reduxjs/toolkit';
import { sendContactMessage,getAllContactInfo } from '../actions';

const initialState = {
    contactUs: [],
    loading: false,
    error: null,
    success: false,
};

const contactUsSlice = createSlice({
    name: 'contactUs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
          
            .addCase(getAllContactInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getAllContactInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
                state.contactUs = action.payload;
            })
            .addCase(getAllContactInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = false;
            })
            
            .addCase(sendContactMessage.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(sendContactMessage.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(sendContactMessage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = false;
            });
    },
});

export default contactUsSlice.reducer;