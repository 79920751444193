import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
    FormControl,
    Select,
    MenuItem,
    TextField,
    Button,
    Typography,
    Box,
} from "@mui/material";
import * as Yup from "yup";
import { appStrings } from "../../../Helper/Constant";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import AuthServices from "../../../services/AuthService";

const initialValues = {
    countryCode: "+91",
    mobileNumber: "",
};

const countryCodes = [
    { code: "+91", label: "+91 [IND]" },
    { code: "+1", label: "+1 [USA]" },
    { code: "+44", label: "+44 [UK]" },
    // Add more country codes as needed
];


const useStyles = makeStyles((theme) => ({
    customTextField: {
        borderRadius: '45px',
        background: theme.palette.grey[1100],
        border: "none",
        outline: "none",
        // Add other styles as needed
    },
    SubmitButton: {
        borderradius: '45px',
        // background: theme.palette.grey[1000],
    },
}));

const LoginForm = ({ setCounter,setMobileNumber, setUserData }) => {
    const { user } = useSelector((state) => state.AuthUser);
    const classes = useStyles();

    // useEffect(() => {
    //   if(user && user.data){
    //     user.data?.token ? setCounter(1) : setCounter(2)
    //   }else{
    //     setCounter(0)
    //   }
    // }, [user?.data])
    


    const handleSubmit = (values, actions) => {
        // Handle login submission here
        const obj = {
            phone: values.mobileNumber
        }
        setMobileNumber(obj.phone)
        AuthServices.customerSignIn(obj).then((res) => {
            if (res.data && res.data.data && res.data.data.otp) {
                setUserData(res.data.data)
                setCounter(1);
            } else {
                setCounter(2);
            }
        }).catch((err) => {
            console.log(err);
            setCounter(2);
        })
        // setCounter(1)
        // actions.setSubmitting(false);
    };

    const validationSchema = Yup.object().shape({
        countryCode: Yup.string().required("Country code is required"),
        mobileNumber: Yup.string().required("Mobile number is required").matches(/^\d{10}$/, 'Please enter valid number'),
        // .matches(/^[6-9]\d{9}$/, 'Mobile number is Invalid')
    });

    return (
        <div>
        <Box marginBottom={3}>
            <Typography variant="h4">{appStrings?.login}</Typography>
            <Typography variant="body1" color="gray">
                Enter your mobile number to proceed
            </Typography>
        </Box>
        <Typography variant="body1" color="gray" marginBottom="10px" fontSize="13px">
            {appStrings?.loginLabel}
        </Typography>
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, mobileNumber,handleChange }) => (
                <Form>
                    <Box
                        display={"flex"}
                        mt={2}
                        border={1}
                        borderRadius={1}
                        borderColor="primary"
                        className={classes.customTextField}
                    >
                        <FormControl sx={{ width: "70px" }}>
                            <Field
                                as={TextField}
                                size="small"
                                id="countryCode"
                                name="countryCode"
                                disabled
                            >
                                <MenuItem key={1} value={'+91'} selected>
                                    +91
                                </MenuItem>
                                {/* {countryCodes.map((country) => (
                                    <MenuItem key={country.code} value={country.code}>
                                        {country.label}
                                    </MenuItem>
                                ))} */}
                            </Field>
                            
                        </FormControl>
                        <FormControl>
                            <Field
                                size="small"
                                as={TextField}
                                type="tel"
                                id="mobileNumber"
                                name="mobileNumber"
                                maxLength={10}
                                value={mobileNumber}  // Use the value prop to control the input value
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Box>
                    <ErrorMessage name="mobileNumber" component="div" style={{color: 'red', textAlign: 'center'}} />
                    <Box py={2}>
                        <Button
                            type="submit"
                            variant="contained"
                            // disabled={isSubmitting}
                            fullWidth
                            className={classes.SubmitButton}
                        >
                            Continue
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    </div>
    );
};

export default LoginForm;
