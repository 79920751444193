import requests from "../Axios/Instance";

const ProductServices = {
  getAllProduct: async (data) => {
    // return requests.get(`/products?title=${data ? data.title : ''}`);
    return requests.get(`/products/show`);
  },
  getProductById: async (id) => {
    return requests.post(`/products/${id}`);
  },
  rateToProducts: async (data) => {
    return requests.post(`/products/ratings/rateToProducts?productId=${data.productId}`,data);
  }
};

export default ProductServices;
 