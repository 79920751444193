import React from 'react'

const HeroProduct = () => {
  return (
    <div><h1>HeroProduct
        </h1> <h1>HeroProduct
        </h1></div>
    
  )
}

export default HeroProduct