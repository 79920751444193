import { createSlice } from "@reduxjs/toolkit";
import { getCustomerById } from "../actions";

const authSlice = createSlice({
  name: "AuthLogin",
  initialState: {
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
    isloggedIn: false,
    isLoading: false,
    status: "Idle",
  },
  reducers: {
    setUser(state,{payload}) {
      state.user = payload;
      localStorage.setItem("user", JSON.stringify(payload))
    },
    logOut(state){
      state.user = null;
      localStorage.setItem("user", null)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isloggedIn = true;
        state.user = payload.data;
        localStorage.setItem("user", JSON.stringify(payload.data))
      })
      .addCase(getCustomerById.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isloggedIn = false;
        state.errorMessage = payload;
      })
  },
});

export default authSlice.reducer;

export const { setUser, setUserMemberShip, logOut } = authSlice.actions;
