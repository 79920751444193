import requests from "../Axios/Instance";

const CouponServices = {
  addCoupon: async (data) => {
    return requests.post(`coupon/add`, data);
  },
  getAllCoupons: async () => {
    return requests.get(`coupon/show`);
  },
  getCouponByCodename: async (code) => {
    return requests.get(`coupon/getCouponByCode/${code}`);
  },
};

export default CouponServices;
