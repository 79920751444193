import requests from "../Axios/Instance";

const TestimonialService = {
  getAllTestimonials: async (data) => {
    return requests.get(`/testimonial/getTestimonial?type=${data.type}`);
    
  },
  getTestimonialById: async (id) => {
    return requests.get(`/testimonial/getTestimonial/${id}`);
  },
  getAllSponsor: async () => {
    return requests.get(`/productfeature/getFeatures`);
  }
};

export default TestimonialService;
