import { createSlice } from "@reduxjs/toolkit";
import { getAllProductQuality, getProductQualityById } from "../actions";  

const initialState = {
  productQualities: [],
  productQualityDetail: { loading: false, error: null, productQuality: null },  
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const ProductQualitySlice = createSlice({
  name: "productQualities",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllProductQuality.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProductQuality.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.productQualities = payload;
      })
      .addCase(getAllProductQuality.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
      .addCase(getProductQualityById.pending, (state) => {
        state.productQualityDetail.loading = true;
      })
      .addCase(getProductQualityById.fulfilled, (state, { payload }) => {
        state.productQualityDetail.loading = false;
        state.productQualityDetail.productQuality = payload;
      })
      .addCase(getProductQualityById.rejected, (state, { payload }) => {
        state.productQualityDetail.loading = false;
        state.productQualityDetail.error = payload;
      });
  },
});

export default ProductQualitySlice.reducer;
