import requests from "../Axios/Instance";

const FAQServices = {
  getAllFAQs: async () => {
    return requests.get("/FAQ/getFAQ");
  },
  getFAQById: async (id) => {
    return requests.get(`/FAQ/${id}`);
  },
};

export default FAQServices;
