import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

import footerBgImage from "../../Assets/Images/footer/footer_bg_img.webp";
import logoImage from "../../Assets/Images/other/logo.webp";
import { appStrings } from "../../Helper/Constant";
import footerLogoImage from "../../Assets/Images/footer/footerLogo.png";
import StyledTitle from "../StyledTitle";
import { useDispatch, useSelector } from "react-redux";
import LazyImage from "../LazyImage";
import { getCategoryList } from "../../Store/actions";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { AiOutlineApple } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  root: {},
  inputWraper: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    width: "100%",
  },
  inputParent: {
    display: "flex",
  },
  inputBtn: {
    background: theme.palette.customizePrimary.main,
    color: theme.palette.secondary.main,
    width: "fit-content",
    borderRadius: "0 5px 5px 0",
    display: "flex",
    alignItems: "center",
    padding: "0 5px",
  },
  scrollBtn: {
    position: "fixed",
    bottom: 70,
    right: 10,
    height: "50px",
    width: "50px",
    border: "2px solid #fdffb3",
    borderRadius: "50%",
  },
  logoBtn: {
    position: "fixed",
    bottom: 10,
    right: 10,
    height: "50px",
    width: "50px",
    border: "2px solid #fdffb3",
    borderRadius: "50%",
    background: "#fff",
    "&:hover": {
      background: "#fff",
    },
  },

  addressItem: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: 6,
    "& .addIcon": {
      fontSize: "20px",
    },
  },
}));

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { data } = useSelector((state) => state.category);
  const [row, setRow] = useState();
  const [pos, setPos] = useState(false);

  useEffect(() => {
    dispatch(getCategoryList());
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        if (!pos) setPos(true);
      } else {
        if (pos) setPos(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pos]);

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPos(false);
  };

  const toggleServices = useCallback(
    (number) => {
      setRow(row === number ? "" : number);
    },
    [row]
  );

  const handleMyContact = useCallback((id) => {
    switch (id) {
      case "a2":
        handleMyEmail();
        break;
      case "a3":
        handleMyWhatsapp();
        break;
      case "a4":
        handleAppStore();
        break;
      case "a5":
        handlePlayStore();
        break;
      default:
        break;
    }
  }, []);

  const handleMyEmail = () => {
    const emailAddress = "hello@lushful.org";
    const emailSubject = "Your subject here";
    const emailBody = "Your message here";

    const mailtoURL = `mailto:${emailAddress}?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoURL;
  };
  const handlePlayStore = () => {
    const playStoreURL = "https://play.google.com";
    window.open(playStoreURL, "_blank");
  };

  const handleAppStore = () => {
    const appStoreURL = "https://apple.com";
    window.open(appStoreURL, "_blank");
  };

  const handleMyWhatsapp = () => {
    const phoneNumber = "+91 8073384821";
    const defaultMessage = "Hello";

    const whatsappURL =
      "https://api.whatsapp.com/send?phone=" +
      phoneNumber +
      "&text=" +
      encodeURIComponent(defaultMessage);

    window.open(whatsappURL);
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundImage: `url(${footerBgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: 3,
        position: "relative"
      }}
    >
      <Container sx={{ color: "#fff" }}>
        <Box sx={{ textAlign: "center" }} pt={4}>
          <Typography
            variant="h3"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={1}
            position="relative"
            sx={{ fontWeight: 800 }}
          >
            Enter your email and we’ll send you the{" "}
            <StyledTitle
              sx={{ fontSize: "25px", fontWeight: 800 }}
              title="Latest Information"
              isGreen={false}
            />
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 800 }}>
            on what to eat to be really healthy.
          </Typography>
          <Box
            className={classes.inputWraper}
            sx={{
              py: 3,
              width: { xs: "100%", sm: "80%", md: "60%", lg: "40%" },
            }}
          >
            <Box
              className={classes.inputParent}
              sx={{
                p: 0.5,
                width: { xs: "100%", sm: "80%", md: "60%", lg: "40%" },
              }}
            >
              <TextField
                id="search-input"
                placeholder="Enter Email Address"
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                  border: "none",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  width: "100%",
                  background: "#fff",
                  outline: "none",
                  padding: 1,
                }}
              />
              <Box
                className={classes?.inputBtn}
                sx={{
                  color: "#000",
                  background: "#FDFFB3",
                  borderRadius: "0 5px 5px 0",
                }}
              >
                {appStrings?.subscribe_now}
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Grid container spacing={4} py={4}>
          {/* Column 1 */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h4"
              pb={3}
              onClick={() => navigate("/")}
              sx={{ cursor: "pointer" }}
            >
              <LazyImage
                src={footerLogoImage}
                alt=""
                style={{ height: "fit-content", width: "fit-content" }}
              />
            </Typography>
            <Typography>
              Healthy, chemical-free food from living soil delivered weekly
              straight to your doorstep
            </Typography>
            <List className={classes.addressList}>
              {[
                {
                  id: "a1",
                  title: "10B/38, 2nd Main, Arekere Road, Bannerghatta, 560076",
                  icon: <IoLocationOutline />,
                  onClick: () => handleMyContact("a1"),
                  cursor: "auto",
                },
                {
                  id: "a2",
                  title: "hello@lushful.org",
                  icon: <FiMail />,
                  onClick: () => handleMyContact("a2"),
                  cursor: "pointer",
                },
                {
                  id: "a3",
                  title: "+91 8073384821",
                  icon: <FaWhatsapp />,
                  onClick: () => handleMyContact("a3"),
                  cursor: "pointer",
                },
                {
                  id: "a4",
                  title: "Download App Now - App Store",
                  icon: <AiOutlineApple />,
                  onClick: () => handleMyContact("a4"),
                  cursor: "pointer",
                },
                {
                  id: "a5",
                  title: "Download App Now - Play Store",
                  icon: <IoLogoGooglePlaystore />,
                  onClick: () => handleMyContact("a5"),
                  cursor: "pointer",
                },
              ].map((addressItem) => (
                <ListItem
                  key={addressItem.id}
                  className={classes.addressItem}
                  onClick={addressItem.onClick}
                  sx={{ cursor: addressItem.cursor }}
                >
                  {addressItem.icon && (
                    <span className="addIcon">{addressItem.icon}</span>
                  )}
                  {addressItem.title}
                </ListItem>
              ))}
            </List>
          </Grid>

          {/* Column 2 */}
          <Grid item xs={12} md={3}>
            <Typography
              variant="h4"
              onClick={() => toggleServices("0")}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // cursor: "pointer"
              }}
            >
              Quick Links
              <Typography display={{ xs: "block", md: "none" }}>
                {row === "0" ? (
                  <MdOutlineKeyboardArrowDown className="footerIcons" />
                ) : (
                  <MdOutlineKeyboardArrowRight className="footerIcons" />
                )}
              </Typography>
            </Typography>
            <Box display={{ xs: "block", md: "none" }}>
              {row === "0" && (
                <List>
                  {[
                    { id: "f1", title: "About Us", route: "about" },
                    { id: "f2", title: "Products", route: "Products" },
                    { id: "f3", title: "Our Farms", route: "ourfarms" },
                    { id: "f4", title: "Blogs", route: "Blogs" },
                    { id: "f6", title: "FAQ", route: "FAQ" },
                    { id: "f7", title: "Contact Us", route: "Contactus" },
                  ].map((item, index) => (
                    <ListItem
                       onClick={() =>
                      window.open(
                        `/${item.route}`,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                      key={index + 30}
                      sx={{ cursor: "pointer" }}
                    >
                      <ListItemText primary={item.title} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
            <Box display={{ xs: "none", md: "block" }}>
              <List>
                {[
                  { id: "f1", title: "About Us", route: "about" },
                  { id: "f2", title: "Products", route: "Products" },
                  { id: "f3", title: "Our Farms", route: "ourfarms" },
                  { id: "f4", title: "Blogs", route: "Blogs" },
                  { id: "f6", title: "FAQ", route: "FAQ" },
                  { id: "f7", title: "Contact Us", route: "Contactus" },
                ].map((item, index) => (
                  <ListItem
                    onClick={() =>
                      window.open(
                        `/${item.route}`,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                    key={index + 30}
                    sx={{ cursor: "pointer", paddingLeft: 0 }}
                  >
                    <ListItemText primary={item.title} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Typography
              variant="h4"
              onClick={() => toggleServices("1")}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              Other Links
              <Typography
                display={{ xs: "block", sm: "block", md: "none" }}
                // onClick={() => toggleServices("1")}
              >
                {row === "1" ? (
                  <MdOutlineKeyboardArrowDown className="footerIcons" />
                ) : (
                  <MdOutlineKeyboardArrowRight className="footerIcons" />
                )}
              </Typography>
            </Typography>
            <Box display={{ xs: "block", sm: "block", md: "none" }}>
              {row === "1" && (
                <List>
                  {[
                    {
                      id: "f1",
                      title: "Privacy Policy",
                      route: "privacy-policy",
                    },
                    {
                      id: "f2",
                      title: "Terms Of Service",
                      route: "terms-service",
                    },
                    {
                      id: "f3",
                      title: "Refund Policy",
                      route: "refund-policy",
                    },
                    {
                      id: "f4",
                      title: "Shipping Policy",
                      route: "shipping-policy",
                    },
                    {
                      id: "f5",
                      title: "Return Policy",
                      route: "return-policy",
                    },
                  ].map((item, index) => (
                    <ListItem
                      onClick={() => {
                        const url = `/${item.route}`;
                        window.open(url, "_blank", "noopener,noreferrer");
                      }}
                      key={index + 30}
                      sx={{ cursor: "pointer", paddingLeft: 0 }}
                    >
                      <ListItemText primary={item.title} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
            <Box display={{ xs: "none", sm: "none", md: "block" }}>
              <List>
                {[
                  {
                    id: "f1",
                    title: "Privacy Policy",
                    route: "privacy-policy",
                  },
                  {
                    id: "f2",
                    title: "Terms Of Service",
                    route: "terms-service",
                  },
                  { id: "f3", title: "Refund Policy", route: "refund-policy" },
                  {
                    id: "f4",
                    title: "Shipping Policy",
                    route: "shipping-policy",
                  },
                  {
                    id: "f5",
                    title: "Return Policy",
                    route: "return-policy",
                  },
                ].map((item, index) => (
                  <ListItem
                    onClick={() => {
                      const url = `/${item.route}`;
                      window.open(url, "_blank", "noopener,noreferrer");
                    }}
                    key={index + 30}
                    sx={{ cursor: "pointer", paddingLeft: 0 }}
                  >
                    <ListItemText primary={item.title} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>

          {/* Column 3 */}
          <Grid item xs={12} sm={12} md={3}>
            <Typography
              variant="h4"
              onClick={() => toggleServices("2")}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              Categories
              <Typography
                display={{ xs: "block", sm: "block", md: "none" }}
                // onClick={() => toggleServices("2")}
              >
                {row === "2" ? (
                  <MdOutlineKeyboardArrowDown className="footerIcons" />
                ) : (
                  <MdOutlineKeyboardArrowRight className="footerIcons" />
                )}
              </Typography>
            </Typography>
            <Box display={{ xs: "block", sm: "block", md: "none" }}>
              {row === "2" && (
                <List>
                  {data?.data?.slice(0, 6).map((item, index) => (
                    <ListItem
                      onClick={() =>
                        navigate(`/Products/${item.name.en}`, { state: item })
                      }
                      key={index + 30}
                      sx={{ cursor: "pointer" }}
                    >
                      <ListItemText primary={item?.name?.en} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
            <Box display={{ xs: "none", sm: "none", md: "block" }}>
              <List sx={{ marginLeft: "-18px" }}>
                {data?.data?.slice(0, 6).map((item, index) => (
                  <ListItem
                    // onClick={() => navigate(`/${item.route}`)}
                    onClick={() =>
                      navigate(`/Products/${item.name.en}`, { state: item })
                    }
                    key={index + 30}
                    sx={{ cursor: "pointer" }}
                  >
                    <ListItemText primary={item?.name?.en} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Button
          className={classes.scrollBtn}
          variant="contained"
          onClick={handleTop}
          size="small"
          style={{
            display: pos ? "flex" : "none",
          }}
        >
          <ArrowUpwardIcon sx={{ color: "#fdffb3" }} />
        </Button>
        <div>
          <Button
            className={classes.logoBtn}
            variant="contained"
            onClick={handleMyWhatsapp}
            size="small"
          >
            <img src={logoImage} alt="logo" height="70%" width="100%" />
          </Button>
        </div>

        <Box sx={{ textAlign: "center", py: 2 }}>
          {/* &copy; 2024 Your Company. All Rights Reserved */}
          Copyright © 2024 Lushful Store. All Rights Reserved
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
