import requests from "../Axios/Instance";

const OurFarmersService = {
  getAllFarmers: async (data) => {
    return requests.get(`/farmer/getFarmers?page=${data.page}&pageSize=${data.pageSize}&type=${data.type} ` );
  },  
  getFarmerById: async (id) => {
    return requests.get(`/farmer/getFarmerById?_id=${id}`);
  },
};

export default OurFarmersService;
