import { createSlice } from "@reduxjs/toolkit";
import { getSearchProdutsOrBaskets } from "../actions";  

const initialState = {
  results: [],
  loading: false,
  error: null,
};

const SearchProdutsOrBasketsSlice = createSlice({
  name: "search",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSearchProdutsOrBaskets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSearchProdutsOrBaskets.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(getSearchProdutsOrBaskets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default SearchProdutsOrBasketsSlice.reducer;
