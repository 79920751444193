import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import './notfound.css'
import PageNotFoundIMG from '../../Assets/Images/404 page/404 1.png'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <Container style={{ background: '#F9F9F9' }}>
      <Box textAlign={"center"} className='NotFoundContainer'>
        <img src={PageNotFoundIMG} alt="PageNotFoundIMG" />
        <Typography className='NotFoundHeading1'>Sorry, Something Went Wrong</Typography>
        <Typography  className='NotFoundHeading2'>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</Typography>
        <button className='NotFoundbtn' sx={{ cursor: "pointer" }} onClick={() => navigate("/")}>Back To Home Page</button>
      </Box>
    </Container>
  )
}

export default NotFound
