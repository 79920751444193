import {
    Box,
    Container,
    IconButton,
    Modal,
    useTheme,
    ListItem,
    List,
    useMediaQuery,
    Button,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import logoImage from "../../Assets/Images/Svg/darkLogo.svg";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountPopover from "./AccontPopover";
import OrderAgainPopover from "./OrderAgainPopover";
import CartPopover from "./CartPopover";
import HeaderSearchBar from "./HeaderSearchBar";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../Pages/AuthPages/Login/LoginModal";
import HamburgerIcon from "./HamburgerIcon";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../Store/Slice/AuthSlice";
import LazyImage from "../LazyImage";
import { CiUser } from "react-icons/ci";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    loginBtn: {
        color: '#20320D',
        width: '26%',
        display: 'flex',
        padding: 10,
        borderRadius: "5px",
        flexDirection: "column",
        gap: "13px",

    },
    loginbtn: {
        color: '#120101',
        display: 'flex',
        width: '106px',
        padding: "2px",
        gap: '11px',
        fontSize: '19px',
        alignItems: "center"

    }
}))

const Header = () => {
    const theme = useTheme();
    const classes = useStyles()
    const [menuOpen, setMenuOpen] = useState(false);
    const [counter, setCounter] = useState(0)
    const { user } = useSelector((state) => state.AuthUser);
    const dispatch = useDispatch()

    const [IsLoginModalOpen, setIsLoginModalOpen] = useState(false)
    const [searchModal, setSearchModal] = useState(false);
    const navigate = useNavigate()
    const [open, setOpen] = useState(null);

    const viewAllBaskets = () => {
        if (user) {
            navigate("/Baskets", { state: { id: 102, category: "My Baskets", key: "Custom" } });
        } else {
            toast('Please first login', {
                position: "top-center",
                type: 'error',
                theme: 'colored'
            });
        }
    };
    const handleOpen = (event) => {
        navigate("/cart")
        setOpen(event.currentTarget);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeSearchBar = () => {
        setSearchModal(!searchModal);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const isSmallScreen = useMediaQuery(theme.breakpoints.up('lg'));


    return (
        <Box sx={{ boxShadow: 1 }}>
            <Container
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "column", lg: "row" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "8px",
                    position: "relative"
                }}
            >
                <Box
                    display={"flex"}
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: { xs: "100%", md: "100%", lg: "auto" },
                        pb: { xs: 2, md: 0 },
                    }}
                >
                    {/* logo images */}
                    <LazyImage src={logoImage} alt="" className="logo" onClick={() => navigate("/")} style={{ cursor: "pointer", height: '100%', width: 'fit-content' }} />
                    <IconButton
                        aria-label="hamburger"
                        onClick={toggleMenu}
                        sx={{ display: { xs: "block", md: "block", lg: "none" } }}
                    >
                        <HamburgerIcon />
                    </IconButton>
                </Box>

                {/* <Box sx={{ display: { xs: "none", md: "none", lg: "block" } }}> */}

                <HeaderSearchBar />
                {/* </Box> */}

                <Box
                    sx={{
                        display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                        justifyContent: 'space-evenly',
                        alignItems: "center",
                        gap: "4px",
                        width: '30%',
                        padding: "4px",
                        paddingLeft: "27px",
                        // boxShadow: 1,
                        // p: 1,
                    }}
                >

                    <CartPopover />


                    <OrderAgainPopover />
                    {
                        user && user.token ? (
                            <AccountPopover />
                        ) : (
                            <Button
                                className={classes.loginBtn}
                                onClick={() => {
                                    setIsLoginModalOpen(true);
                                    setCounter(0);
                                }}
                                style={{ padding: "8px" }} // Adjust padding as needed
                            >
                                <span style={{ width: "33px", height: "28px", display: "inline-block", marginTop: "4px" }}>
                                    <CiUser style={{ width: "100%", height: "100%" }} />
                                </span>
                                <Typography variant="h4" sx={{ color: '#545454', }}>log In</Typography>
                            </Button>
                        )
                    }


                </Box>

                <Modal open={!isSmallScreen && menuOpen} onClose={toggleMenu}>
                    <Box
                        sx={{
                            position: "fixed",
                            top: 0,
                            right: 0,
                            height: "100%",
                            background: "white",
                            padding: "9px",
                            width: "262px",
                        }}
                    >
                        <Box py={4}>
                            <LazyImage src={logoImage} alt="ss" />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                justifyContent: "space-between",
                                height: "86%",
                            }}>
                            <Box>
                                <List>
                                    <ListItem button onClick={toggleMenu}>
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: '5px',
                                        }}  onClick={handleOpen}>
                                            <CartPopover />
                                            <Typography sx={{ color: "#545454" }}>Cart</Typography>
                                        </Box>
                                    </ListItem>
                                    <ListItem button onClick={toggleMenu}>
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: '5px',
                                        }}    onClick={viewAllBaskets}>
                                            <OrderAgainPopover />
                                            <Typography sx={{ color: "#545454" }}>My Basket</Typography>
                                        </Box>
                                    </ListItem>
                                    <ListItem button>
                                        {user && user.token ? (
                                            <div onClick={() => {
                                                navigate('/profile')
                                                toggleMenu()
                                            }}>
                                                <AccountPopover />
                                            </div>
                                        ) : (
                                            <Button
                                                // startIcon={<PersonOutlineOutlinedIcon />}
                                                className={classes.loginbtn}
                                                onClick={() => {
                                                    setIsLoginModalOpen(true);
                                                    setCounter(0);
                                                    toggleMenu()
                                                }}
                                            >
                                                <span style={{ width: "33px", height: "28px", display: "inline-block", marginTop: "4px" }}>
                                                    <CiUser style={{ width: "100%", height: "100%" }} />
                                                </span>
                                                <Typography variant="h4" sx={{ color: '#545454', }}>log In</Typography>
                                              
                                            </Button>
                                        )}
                                    </ListItem>
                                </List>
                            </Box>

                            <Box sx={{ bottom: "0" }}>
                                <List>
                                    <ListItem button  >
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}>
                                            <Typography>
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M2 12L7 16V13H16V11H7V8L2 12Z" fill="#3F2813" />
                                                        <path d="M13.001 2.99906C11.8185 2.99578 10.6471 3.22717 9.55474 3.67982C8.46233 4.13247 7.4706 4.79739 6.63699 5.63606L8.05099 7.05006C9.37299 5.72806 11.131 4.99906 13.001 4.99906C14.871 4.99906 16.629 5.72806 17.951 7.05006C19.273 8.37206 20.002 10.1301 20.002 12.0001C20.002 13.8701 19.273 15.6281 17.951 16.9501C16.629 18.2721 14.871 19.0011 13.001 19.0011C11.131 19.0011 9.37299 18.2721 8.05099 16.9501L6.63699 18.3641C8.33599 20.0641 10.596 21.0011 13.001 21.0011C15.406 21.0011 17.666 20.0641 19.365 18.3641C21.065 16.6651 22.002 14.4051 22.002 12.0001C22.002 9.59506 21.065 7.33506 19.365 5.63606C18.5314 4.79739 17.5397 4.13247 16.4472 3.67982C15.3548 3.22717 14.1835 2.99578 13.001 2.99906Z" fill="#3F2813" />
                                                    </svg>
                                                </span>
                                            </Typography>
                                            <Button
                                                sx={{
                                                    color: "var(--Secondary-2, #3F2813)",
                                                    fontSize: "17px",
                                                    fontFamily: 'Poppins'
                                                }}
                                                onClick={() => {
                                                    dispatch(logOut())
                                                    navigate('/')
                                                    handleClose(); // Close the Popover when Button is clicked
                                                }}
                                            >
                                                Logout
                                            </Button>
                                        </Box>

                                    </ListItem>
                                </List>
                            </Box>
                        </Box>

                    </Box>
                </Modal>

                <LoginModal IsLoginModalOpen={IsLoginModalOpen} setIsLoginModalOpen={setIsLoginModalOpen} counter={counter} setCounter={setCounter} />
            </Container >
        </Box >
    );
};

export default Header;
