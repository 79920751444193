import { createSlice } from "@reduxjs/toolkit";
import { getCategoryList, getHomeCategoryList } from "../actions";

const initialState = {
  data: [],
  homeData: [],
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getHomeCategoryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHomeCategoryList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.homeData = payload;
      })
      .addCase(getHomeCategoryList.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
      .addCase(getCategoryList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload;
      });
  },
});

export default categorySlice.reducer;
