import requests from "../Axios/Instance";

const MembershipService = {
  getMembershipPlans: async (data) => {
    return requests.get(`/membership/getMembershipPlans?page=${data.page}&pageSize=${data.pageSize}&type=${data.type}`);
  },
  getMembershipById: async (id) => {
    return requests.get(`/membership/getSinglePlan?_id=${id}`);
  },
  buyMembership: async (planId) => {
    return requests.put(`/membership/buyMembership?planId=${planId.planId}&membershipReferenceId=123456`);
  },
  cancelMembership: async (planId) => {
    return requests.put(`/membership/cancelMembership`,planId);
  }
}; 

export default MembershipService;
