import requests from "../Axios/Instance";

const BlogService = {
    getAllBlog: async (data) => {
        return requests.get(`/blog/getBlogByProductOrBasket?productOrBasketId=${data.productOrBasketId}`);
    },
    getAllProductOrBasketBlog: async (data) => {
        return requests.get(`/blog/getBlog?type=${data.type}`);
    },
    getBlogById: async (id) => {
        return requests.get(`blog/getBlogById/${id}`);
    },
    getAllBlogCategory: async (data) => {
        return requests.get(`/blogCategory/getBlogCategory?type=${data.type}`);
    },

};

export default BlogService;
