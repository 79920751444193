import { createSlice } from '@reduxjs/toolkit';
import { updateCustomerProfileImage } from '../actions'; // Import the async thunk action

// Initial state for the slice
const initialState = {
    profileImage: [],
    loading: false,
    error: null,
};


const EditProfileImageSlice = createSlice({
    name: 'profileImage',
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(updateCustomerProfileImage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateCustomerProfileImage.fulfilled, (state, action) => {
                state.loading = false;
                state.profileImage = action.payload;  
            })
            .addCase(updateCustomerProfileImage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;  
            });
    },
});
export default EditProfileImageSlice.reducer;
 
