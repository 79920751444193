import { createSlice } from "@reduxjs/toolkit";
import { getAllFarmers, getFarmerById } from "../actions";

const initialState = {
  farmers: [], // Corrected property name
  farmerDetail: null,
  loading: false,
  error: null,
};

export const OurFarmersSlice = createSlice({
  name: "farmers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllFarmers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllFarmers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.farmers = action.payload;  
      })
      .addCase(getAllFarmers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getFarmerById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFarmerById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.farmerDetail = payload;  
      });
  },
});

export default OurFarmersSlice.reducer;
