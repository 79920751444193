import requests from "../Axios/Instance";

const BasketServices = {
  getHomeBasket: async (data) => {
    return requests.get(`/basket/getBaskets?page=${data.page}&pageSize=${data.pageSize}&type=${data.type}${data.customerId ? `&customerId=${data.customerId}` : ''}`);
  },
  getBasketById: async (id) => {
    return requests.get(`/basket/getBasket?basketId=${id}`);
  },
  deleteBasket: async (id) => {
    return requests.delete(`/basket/deleteBasket/${id}`);  // Assuming the correct method and URL format
  },
  createBasket: async (basketData) => {
    return requests.post("/basket/createBasket", basketData);
  },
  rateToBaskets: async (data) => {
    return requests.post(`/basket/rateToBaskets?basketId=${data.basketId}`,data);
  },
  editBasket: async (data) => {
    return requests.put(`/basket/editBaskets?basketId=${data.basketId}`,data);
  }
   
};

export default BasketServices;
