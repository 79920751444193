import { createSlice } from "@reduxjs/toolkit";
import { getCarts } from "../actions";

const initialState = {
  data: [],
  product: null,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCart(state,{payload}) {
      state.data.push({...payload});
    },
    clearCart(state){
      state.data = []
    },
    updateCart(state, action) {
      const { _id } = action.payload;
      state.data = state.data.map(item => {
        if (item._id === _id) {
          return {...action.payload};
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCarts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCarts.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = payload.data;
        state.gstInfo = payload.GSTInfo
      })
      .addCase(getCarts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
  },
});

export default cartSlice.reducer;
export const { addCart, clearCart, updateCart } = cartSlice.actions;
