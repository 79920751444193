import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import HeroSectionDetail from "../Pages/ProductDetail/Section/HeroSection";

const PageDetailLayout = () => {
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <>
            <HeroSectionDetail />
            <Outlet />
        </>
    );
};

export default PageDetailLayout;
