import React, { useEffect, useState } from "react";
import LoginForm from "./LoginFrom";
import { Box, Button, Grid, Modal } from "@mui/material";
import authImage from "../../../Assets/Images/Svg/authImageModal.svg";
import smallAuthImage from "../../../Assets/Images/Svg/smallAuthImage.svg";
import OtpValidation from "./OtpValidation";
import SignUpForm from "./SignUpForm";
import { CloseIcon } from "../../../Component/CloseIcon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  alignItems: "center",
  // borderRadius: 2,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  backgroundColor: '#FCFFF9',
  // p: 4,
  height: 'auto', // Set height to auto
  maxHeight: '87%', // Set a maximum height to avoid excessive scrolling
  overflowY: 'auto', // Enable vertical scrolling if the content is too tall
  overflowX: 'hidden', // Hide horizontal scrollbar
};

const LoginModal = ({ IsLoginModalOpen, setIsLoginModalOpen, counter, setCounter }) => {

  const [inputValue, setInputValue] = useState('');
  const [mobileNumber, setMobileNumber] = useState("")
  const [userData, setUserData] = useState(null)
  // const classes = useStyles();
  // const theme = useTheme();

  const handleClose = () => setIsLoginModalOpen(false);
  return (
    <>
      <Modal
        open={IsLoginModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={"modal_width"} sx={style}>
          <div className="wraper_auth_page">
            <img src={smallAuthImage} alt="smallAuthImage" className="small-screen-show" />
            <img src={authImage} alt="authImage" className="large-screen-show" />
            <Box sx={{
              backgroundColor: '#FCFFF9', paddingLeft: '29px',
              paddingRight: '52px',
            }}>
              {/* close icon */}
              <Button
                onClick={() => {
                  setCounter(0);
                  setIsLoginModalOpen(false);
                }}
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                {CloseIcon}
              </Button>

              <Box className="height_form">
                {counter === 0 ? (
                  <LoginForm setCounter={setCounter} setInput={setInputValue} setMobileNumber={setMobileNumber} mobileNumber={mobileNumber} setUserData={setUserData}  />
                ) : counter === 1 ? (
                  <OtpValidation setCounter={setCounter} inputValue={inputValue}  mobileNumber={mobileNumber} setIsLoginModalOpen={setIsLoginModalOpen} userData={userData} setUserData={setUserData} />
                ) : (
                  <SignUpForm setCounter={setCounter} setMobileNumber={setMobileNumber} mobileNumber={mobileNumber} setUserData={setUserData} />
                )}
              </Box>
            </Box>
          </div>
        </Box >
      </Modal >
    </>
  );
};

export default LoginModal;
