import requests from "../Axios/Instance";

const OurTeamService = {
  getAllOurTeam: async (data) => {
    return requests.get(`/ourteam/getTeams?type=${data.type}`);
  },
  getOurTeamById: async (id) => {
    return requests.get(`/OurTeam/${id}`);
  },
};

export default OurTeamService;
