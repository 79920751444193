import { createSlice } from "@reduxjs/toolkit";
import { getAllAds, getAllBanners, getBannersById } from "../actions";

const initialState = {
  banners: [],
  adsList: [],
  bannerDetail: null,
  loading: false,
  error: null,
};

export const BannerSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBanners.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllBanners.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.banners = action.payload;
      })
      .addCase(getAllBanners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBannersById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBannersById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.bannerDetail = payload;
      })
      .addCase(getBannersById.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getAllAds.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.adsList = payload;
      });
  },
});

export default BannerSlice.reducer;
