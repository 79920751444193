import { Box, Button, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useState } from "react";
import { appStrings } from "../../../Helper/Constant";
import { makeStyles } from "@mui/styles";
import { setUser } from "../../../Store/Slice/AuthSlice";
import { useDispatch } from "react-redux";
import AuthServices from "../../../services/AuthService";
import { getCustomerById } from "../../../Store/actions";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    time: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    SubmitButton: {
        borderradius: '45px',
        // background: theme.palette.grey[1000],

    }
}))

const OtpValidation = ({ setCounter, mobileNumber, inputValue, setIsLoginModalOpen,userData, setUserData }) => {
    const navigate = useNavigate()
    const classes = useStyles();
    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const handleComplete = (finalValue) => {
        console.log(finalValue);
    };
    const handleSubmit = () =>{
        AuthServices.customerVerifyOtp({id: userData._id, otp: value}).then((res) => {
            setIsLoginModalOpen(false)
            setCounter(0)
            dispatch(getCustomerById(userData._id))
            navigate('/')
        }).catch((error) => {
            console.log('err',error);
            setError(error.response.data.message)
        })
    }

    const handleReset = () => {
        const obj = {
            phone: mobileNumber
        }
        AuthServices.customerSignIn(obj).then((res) => {
            if (res.data && res.data.data && res.data.data.otp) {
                setUserData(res.data.data)
                setCounter(1);
            } else {
                setCounter(2);
            }
        }).catch((err) => {
            console.log(err);
            setCounter(2);
        })
    };

    return (
        <Box>
            <Typography variant="h5" color="primary">
                {appStrings?.otpTitle}
            </Typography>

            <Typography color="gray">
                {appStrings?.otpDesc} <span>{mobileNumber}</span>
            </Typography>

            <Typography color="gray">
                OTP <span>{userData.otp}</span>
            </Typography>

            <Box textAlign={"center"}>
                <Box py={3}>
                    <MuiOtpInput
                        length={6}
                        value={value}
                        className="customInput"
                        onComplete={handleComplete}
                        onChange={handleChange}
                    />
                    <Typography textAlign={"start"} color={"gray"} pt={1}>
                        Didn’t receive the OTP? <span onClick={handleReset} style={{cursor: 'pointer'}}>Resend</span> 
                        {/* in <span className={classes.time}>00.24
                        </span> */} 
                    </Typography>
                </Box>
                {error && <Typography style={{color: 'red'}}>{error}</Typography>}

                <Button onClick={handleSubmit} fullWidth color="primary" variant="contained" className={classes.SubmitButton}>
                Enter OTP
                </Button>
            </Box>
        </Box>
    );
};

export default OtpValidation;
