import requests from "../Axios/Instance";

const SearchProdutsOrBaskets = {
  getSearchProdutsOrBaskets: async (data) => {
    return requests.get(`/customer/Search/ProdutsOrBaskets?type=${data.type}&subType=${data.subType}&id=${data.id}&string=${data.string} `);
  },
  
};

export default SearchProdutsOrBaskets;
