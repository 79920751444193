import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
    Box,
    Typography,
    colors
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import cart from "../../Assets/Images/Svg/cart.svg";
import { useNavigate } from "react-router-dom";
import { Badge } from "@mui/base";
import { getCarts } from "../../Store/actions";
import MailIcon from '@mui/icons-material/Mail';
import LazyImage from "../LazyImage";
export default function CartPopover({ profile, setProfile }) {
    const [open, setOpen] = useState(null);
    const { data } = useSelector((state) => state.carts);
    const { user } = useSelector((state) => state.AuthUser);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getCarts())
    }, [user])



    const handleOpen = (event) => {
        navigate("/cart")
        setOpen(event.currentTarget);
    };


    return (
        <>
            <Box
                onClick={handleOpen}
                sx={{
                    p: 0,
                    background: "none",
                    display: "flex",
                    cursor: "pointer",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0px",
                    ...(open && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            borderRadius: "50%",
                            position: "absolute",
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                {/* <Badge color="secondary" badgeContent={0} showZero>
        <MailIcon />
      </Badge> */}
                {user && data?.length > 0 ? (
                    <>
                        <Badge badgeContent={data?.length} color="primary" anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                            <LazyImage
                                src={cart ? cart : "https://cdn.vectorstock.com/i/preview-1x/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg"}
                                alt="profile"
                                style={{ width: "52px", height: "52px", padding: 10, borderRadius: "31%", background: '#ffff', color: 'D6D6D6' }}
                            />
                        </Badge>
                        <Box
                            sx={{
                                padding: '5px',
                                width: '69px',
                                display: {
                                    xs: 'none',  
                                    md:"none",
                                    lg: 'block',  
                                },
                            }}
                        >
                            <Typography variant="h4" sx={{ color: '#8A8A8A' }}>
                                Cart
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <>
                        <LazyImage
                            src={cart ? cart : "https://cdn.vectorstock.com/i/preview-1x/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg"}
                            alt="profile"
                            style={{ width: "52px", height: "52px", padding: 10, borderRadius: "31%", background: '#ffff', color: "D6D6D6" }}
                        />
                        <Box sx={{
                               
                                display: {
                                    xs: 'none',  
                                    md:"none",
                                    lg: 'block', 
                                },
                            }}>
                            <Typography variant="h4" sx={{ color: "#8A8A8A" }}>Cart</Typography>
                        </Box>
                    </>
                )}
            </Box>

        </>
    );
}
