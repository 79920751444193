import React, { useEffect, useState } from "react";
import SubHeader from "../Component/Header/SubHeader";
import Header from "../Component/Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../Component/Footer/Footer";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    subHeader: {
        position: 'fixed',
        top: 0,
        width: '100%',
        backgroundColor: '#ffffff', 
        zIndex: 111,
    },
    content: {
        paddingTop: "20px", // Default padding top
    }
}));

const SubHeaderSticky = () => {
    const classes = useStyles();
    return (
        <div className={classes.subHeader}>
            <SubHeader />
            <Header />
        </div>
    );
};


const HomePageLayout = () => {
    const classes = useStyles();
    const [paddingTop, setPaddingTop] = useState("20px");

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if(width >= 1200) {
                setPaddingTop("133px");
            }else if (width >= 766) {
                setPaddingTop("180px"); // Adjust this value for larger screens
            } else {
                setPaddingTop("194px"); // Default value for smaller screens
            }
        };

        handleResize(); // Initial call
        window.addEventListener("resize", handleResize); // Listen for resize events

        return () => {
            window.removeEventListener("resize", handleResize); // Clean up on unmount
        };
    }, []);

    return (
        <>
            <SubHeaderSticky />
            <Box className={classes.content} style={{ paddingTop }}>
                <Outlet />
            </Box>
            <Footer />
        </>
    );
};

export default HomePageLayout;
