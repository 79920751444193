import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePageLayout from "./Layout/HomePageLayout";
import PageDetailLayout from "./Layout/PageDetailLayout";
import NotFoundPage from "./Pages/NotFound/NotFound";
import HeroProduct from "./Pages/ProductDetail/HeroProduct";
import PrivateRoute from "./Helper/PrivateRoute";
import Returnpolicy from "./Pages/Policys/Returnpolicy";

const Index = lazy(() => import("./Pages/HomePage/Index"));
const AboutIndex = lazy(() => import("./Pages/About/Index"));
const OurFarmers = lazy(() => import("./Pages/OurFarmers/OurFarmers"));
const Faq = lazy(() => import("./Pages/FAQ/Faq"));
const Profile = lazy(() => import("./Pages/Profile/Profile"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const CartPage = lazy(() => import("./Pages/Cart/CartPage"));
const SuccessfullMsg = lazy(() => import("./Pages/SuccessfullMsg/SuccessfullMsg"));
const Checkout = lazy(() => import("./Pages/Cart/Checkout"));
const MembershipSuccessful = lazy(() => import("./Pages/SuccessfullMsg/MembershipSuccessful"));
const OrderDetail = lazy(() => import("./Pages/OrderPage/OrderDetail"));
const Blogs = lazy(() => import("./Pages/Blogs/Blogs"));
const ReviewPage = lazy(() => import("./Pages/ProductDetail/ProductReviewPage/ReviewPage"));
const BasketInfo = lazy(() => import("./Pages/ProductDetail/SingleProduct/BasketInfo"));
const BasketSection = lazy(() => import("./Pages/ProductDetail/Section/BasketSection"));
const MainSection = lazy(() => import("./Pages/ProductDetail/Section/MainSection"));
const ProductInfo = lazy(() => import("./Pages/ProductDetail/SingleProduct/ProductInfo"));
const BasketPage = lazy(() => import("./Pages/ProductDetail/ProductReviewPage/BasketReview"));
const MyBasketDetails = lazy(() => import("./Pages/ProductDetail/SingleProduct/MyBasketDetails"));
const AllBlogs = lazy(() => import("./Pages/Blogs/AllBlogs"));
const PrivacyPolicy = lazy(() => import("./Pages/Policys/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("./Pages/Policys/RefundPolicy"));
const ShippingPolicy = lazy(() => import("./Pages/Policys/ShippingPolicy"));
const TermsOfService = lazy(() => import("./Pages/Policys/TermsOfService"));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<HomePageLayout />}>
          <Route path="/" element={<Suspense fallback={<div>Loading...</div>}><Index /></Suspense>} />
          <Route element={<PageDetailLayout />}>
            <Route path="/about" element={<Suspense fallback={<div>Loading...</div>}><AboutIndex /></Suspense>} />
            {/* <Route path="/profile" element={<Suspense fallback={<div>Loading...</div>}><Profile /></Suspense>} /> */}
            <Route path="/OurFarms" element={<Suspense fallback={<div>Loading...</div>}><OurFarmers /></Suspense>} />
            <Route path="/faq" element={<Suspense fallback={<div>Loading...</div>}><Faq /></Suspense>} />

            <Route path="/Baskets" element={<Suspense fallback={<div>Loading...</div>}><BasketSection /></Suspense>} />
            <Route path="/Baskets/:id/:name" element={<Suspense fallback={<div>Loading...</div>}><BasketInfo /></Suspense>} />
            <Route path="/Products" element={<Suspense fallback={<div>Loading...</div>}><MainSection /></Suspense>} />
            <Route path="/Products/:category" element={<Suspense fallback={<div>Loading...</div>}><MainSection /></Suspense>} />
            <Route path="/Products/:id/:name" element={<Suspense fallback={<div>Loading...</div>}><ProductInfo /></Suspense>} />
            <Route path="/productReview/:id" element={<Suspense fallback={<div>Loading...</div>}><ReviewPage /></Suspense>} />
            <Route path="/basketReview/:id" element={<Suspense fallback={<div>Loading...</div>}><BasketPage /></Suspense>} />

            <Route path="/Contactus" element={<Suspense fallback={<div>Loading...</div>}><Contact /></Suspense>} />

            <Route path="/cart" element={<Suspense fallback={<div>Loading...</div>}><CartPage /></Suspense>} />
            <Route path="/Checkout" element={<Suspense fallback={<div>Loading...</div>}><Checkout /></Suspense>} />
            <Route path="/order/:id" element={<Suspense fallback={<div>Loading...</div>}><OrderDetail /></Suspense>} />
            <Route path="/Blogs/:id" element={<Suspense fallback={<div>Loading...</div>}><Blogs /></Suspense>} />
            <Route path="/Basket_Details/:id/:name" element={<Suspense fallback={<div>Loading...</div>}><MyBasketDetails /></Suspense>} />
            <Route path="/membershipSuccessful" element={<Suspense fallback={<div>Loading...</div>}><MembershipSuccessful /></Suspense>} />
            <Route path="/privacy-policy" element={<Suspense fallback={<div>Loading...</div>}><PrivacyPolicy /></Suspense>} />
            <Route path="/refund-policy" element={<Suspense fallback={<div>Loading...</div>}><RefundPolicy /></Suspense>} />
            <Route path="/shipping-policy" element={<Suspense fallback={<div>Loading...</div>}><ShippingPolicy /></Suspense>} />
            <Route path="/return-policy" element={<Suspense fallback={<div>Loading...</div>}><Returnpolicy/></Suspense>} />
            <Route path="/terms-service" element={<Suspense fallback={<div>Loading...</div>}><TermsOfService /></Suspense>} />
            <Route path="/Blogs" element={<Suspense fallback={<div>Loading...</div>}><AllBlogs /></Suspense>} />

          </Route>
          <Route path="/Hero" element={<Suspense fallback={<div>Loading...</div>}> <HeroProduct /></Suspense>} />
          <Route path="/successfullMsg/:id" element={<Suspense fallback={<div>Loading...</div>}><SuccessfullMsg /></Suspense>} />

          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Suspense fallback={<div>Loading...</div>}>
                  <Profile />
                </Suspense>
              </PrivateRoute>
            }
          />

          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
