import { createSlice } from "@reduxjs/toolkit";
import { getAllOurTeam } from "../actions";

const initialState = {
  ourTeams: [],
  ourTeamMemberDetail: null,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const OurTeamSlice = createSlice({
  name: "ourTeams",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllOurTeam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllOurTeam.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.ourTeams = payload;
      })
      .addCase(getAllOurTeam.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
      
  },
});

export default OurTeamSlice.reducer;
