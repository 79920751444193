import requests from "../Axios/Instance";

const ContactService = {
  sendContactMessage: async (messageData) => {
    return requests.post("/customer/contactus", messageData);
  },
  getAllContactInfo: async () => {
    return requests.get("/setting/global/all");
},
};

export default ContactService;
