import React, { useEffect, useState } from 'react'
import OtherLinks from '../../services/OtherLinks'
import { Box, Button, Container } from '@mui/material'
import BlogDescription from '../Blogs/BlogDescription'
import { useNavigate } from 'react-router-dom'
import { BackSvg } from "../../Component/BackIcon";

const Returnpolicy = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null)
  useEffect(() => {
    OtherLinks.getLink('Return Policy').then((res) => {
      if (res && res.data && res.data.data.length) {
        setData(res.data.data[0])
      }
    })
  }, [])
  if (!data) return null;
  return (
    <Box sx={{ padding: 5 }}>
      {/* <div dangerouslySetInnerHTML={{ __html: data?.description }} />  */}
      <Container>
        
        <Box py={3}>
          <Box sx={{ marginTop: "10px", background: "#FFF", padding: "40px" }}>
            <BlogDescription description={data.description} />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Returnpolicy