import { createSlice } from "@reduxjs/toolkit";
import { getAllTestimonials, getTestimonialById } from "../actions";

const initialState = {
  testimonials: [],
  testimonialDetail: null,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const TestimonialSlice = createSlice({
  name: "testimonials",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllTestimonials.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTestimonials.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.testimonials = payload;
      })
      .addCase(getAllTestimonials.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
      .addCase(getTestimonialById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTestimonialById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.testimonialDetail = payload;
      });
  },
});

export default TestimonialSlice.reducer;
