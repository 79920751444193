import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
    Box,
    Divider,
    Typography,
    Stack,
    MenuItem,
    Avatar,
    IconButton,
    Popover,
    useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import orderAgain from "../../Assets/Images/Svg/orderagain.svg";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import { appStrings } from "../../Helper/Constant";
import LazyImage from "../LazyImage";
import MyBasket from "../../Assets/Images/MyBasket/MyBasket_Img.png"
// mocks_
// import account from "../../../_mock/account";
// import { authLogOut } from "../../../Axios/ApiCall";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: "Saved Combos",
        icon: "eva:home-fill",
    },

    {
        label: 'Most ordered',
        icon: 'eva:person-fill',
    },
    {
        label: 'Recent Orders',
        icon: 'eva:settings-2-fill',
    },
];



const useStyles = makeStyles((theme) => ({
    profileMenu: {
        background: theme.palette.primary.main,
        color: '#fff',
    },
}));
// ----------------------------------------------------------------------

export default function OrderAgainPopover({ profile, setProfile }) {
    const [open, setOpen] = useState(null);
    const { user } = useSelector((state) => state.AuthUser);
    const navigate = useNavigate();

    const classes = useStyles();

    const theme = useTheme();

    // const {
    //     user: { userInfo },
    // } = useSelector((state) => state.AuthUser);

    //   const navigate = useNavigate();

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };
    const viewAllBaskets = () => {
        if (user) {
            navigate("/Baskets", { state: { id: 102, category: "My Baskets", key: "Custom" } });
        } else {
            toast('Please first login', {
                position: "top-center",
                type: 'error',
                theme: 'colored'
            });
        }
    };



    return (
        <>
            <Box
                sx={{
                    p: 0,
                    display: "flex",
                    alignItems: "center",
                    background: "none",
                    cursor: "pointer",
                    flexDirection: "column",
                    gap: 1,
                    ...(open && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            p: 1,
                            position: "absolute",
                        },
                    }),
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between', gap: "5px", flexDirection: "column" }}  onClick={viewAllBaskets}>
                    <Box sx={{ display: "flex", height: "45px", width: "45px", background: '#FFFFFF', alignItems: "center", justifyContent: 'center', borderRadius: '48px', }}>
                        <LazyImage
                            src={MyBasket ? MyBasket : MyBasket}
                            alt="profile"
                            style={{ width: "30px", height: "30px", borderRadius: "30%", alignItems: "center", justifyContent: "center", display: "flex" }}
                        />
                    </Box>
                    < Typography variant="h4" sx={{
                        color: "#8A8A8A", display: {
                            xs: 'none',
                            md: "none",
                            lg: 'block',
                        },
                    }}>My Basket</Typography>
                </Box>
            </Box>


            {/* ------------------------------if  u want to menulist ....then use below the  code ------------------------------------------------------------------------------------- */}

            {/* <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        "& .MuiMenuItem-root": {
                            typography: "body2",
                            borderRadius: 0,
                        },
                    },
                }}
            >
                <Stack
                 className={classes.profileMenu}
                    sx={{
                        p: 1,                        
                    }}
                >
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem
                            key={option.label}
                            onClick={() => {
                                // navigate("/dashboard/app");
                                // setProfile(true);
                                setOpen(null);
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>
                
            </Popover> */}
        </>
    );
}
