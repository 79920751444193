import { Box, Container, Typography, useTheme } from "@mui/material";
import React from "react";
import heroImage from "../../../Assets/Images/other/banner.png";
import BreadCumbs from "../../../Component/Breadcumbs";
import { useLocation, useParams } from "react-router-dom";

const HeroSectionDetail = () => {
  let theme = useTheme();
  const { id } = useParams()
  const currentPathName = useLocation().pathname;

  // const navigate = useNavigate();

  const currentPathname2 = currentPathName.split("/").filter((x) => x);

  return (
    <Box
      sx={{
        py: 8,
        width: "100%",
        color: theme.palette.common.white,
        display: "flex",
        justifyContent: "center",
        backgroundImage: `url(${heroImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Typography variant="h5" textAlign="center">
          <BreadCumbs />
        </Typography>
        <Typography variant="h2" textAlign="center">
          {formatBreadcrumbText(currentPathname2[currentPathname2?.length - 1], id ? currentPathname2[currentPathname2.length - 3] === 'Baskets' || currentPathname2[currentPathname2.length - 3] === 'Products' || currentPathname2[currentPathname2.length - 3] === 'Basket_Details' ? currentPathname2[currentPathname2.length - 3] : currentPathname2[currentPathname2.length - 2] : null)}
        </Typography>
      </Container>{" "}
    </Box>
  );
};

export default HeroSectionDetail;


const formatBreadcrumbText = (text, id) => {
  if (id) {
    return id === 'Products' ? 'Product Detail' :
      id === 'Baskets' ? 'Basket Detail' :
        id === 'productReview' ? "Product Detail" :
          id === 'basketReview' ? "Basket Detail" :
            id === 'order' ? 'Order Detail' :
              id === 'Blogs' ? 'Blog Detail' :
                id === 'Basket_Details' ? 'Basket Details' :
                  '';

  }
  switch (text.toLowerCase()) {
    case 'profile':
      return 'My Account';
    case 'contactus':
      return 'Contact us';
    case 'about':
      return 'About Us';
    case 'ourfarms':
      return 'Our Farmers';
    case 'orderdetail':
      return 'Order Detail';
    case 'membershipsuccessful':
      return 'Membership';
    case 'cart':
      return 'Cart';
    case 'privacy-policy':
      return 'Privacy Policy';
    case 'refund-policy':
      return 'Refund Policy';
    case 'shipping-policy':
      return 'Shipping Policy';
    case 'terms-service':
      return 'Terms Service';
    case 'return-policy':
      return 'Return Policy';
    case 'Blogs':
      return 'All Blogs';
    default:
      return text;
  }
}
