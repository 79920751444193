
import { createAsyncThunk } from "@reduxjs/toolkit";
import CategoryServices from "../services/CategoryService";
import ProductServices from "../services/ProductService";
import BasketServices from "../services/BasketServie";
import AuthServices from "../services/AuthService";
import OrderServices from "../services/OrderService";
import CouponServices from "../services/CouponServices";
import CartServices from "../services/CartService";
import FAQServices from "../services/FAQService";
import MembershipServices from "../services/MembershipService";
import BannerServices from "../services/BannerService";
import TestimonialService from "../services/TestimonialService";
import AboutUsService from "../services/AboutUsService";
import BlogServices from "../services/BlogService";
import OurFarmersService from "../services/OurFarmersService";
import OurTeamService from "../services/OurTeamService";
import ContactService from "../services/ContactService";
import ProductQualityService from "../services/ProductQualityService";
import MarqueeService from "../services/MarqueeService";
import EditProfileImageService from "../services/EditProfileImageService";
import UploadfilesService from "../services/UploadfilesService";
import SearchProdutsOrBaskets from "../services/SearchProdutsOrBaskets";
import MostPurchasedService from "../services/MostPurchasedService";



export const customerSignIn = createAsyncThunk('customer/signIn', async (body, { rejectWithValue }) => {
  try {
    const { data } = await AuthServices.customerSignIn(body);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

export const customerSignUp = createAsyncThunk('customer/signUp', async (body, { rejectWithValue }) => {
  try {
    const { data } = await AuthServices.customerSignUp(body);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

export const getCustomerById = createAsyncThunk('customer/getById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await AuthServices.getCustomerById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

export const getCategoryList = createAsyncThunk('category/getCategoryList', async (data, { rejectWithValue }) => {
  try {
    const { data } = await CategoryServices.getAllCategory();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

export const getHomeCategoryList = createAsyncThunk('category/getHomeCategoryList', async (data, { rejectWithValue }) => {
  try {
    const { data } = await CategoryServices.getAllHomeCategory();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

export const getProductList = createAsyncThunk('product/getProductList', async (body, { rejectWithValue }) => {
  try {
    const { data } = await ProductServices.getAllProduct(body);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

export const getProductById = createAsyncThunk('product/getProductById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await ProductServices.getProductById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

export const getBasketList = createAsyncThunk('basket/getBasketList', async (body, { rejectWithValue }) => {
  try {
    const { data } = await BasketServices.getHomeBasket(body);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

export const getBasketById = createAsyncThunk('basket/getBasketById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await BasketServices.getBasketById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

export const createBasket = createAsyncThunk('basket/createBasket', async (requestData, { rejectWithValue }) => {
  try {
    const { data } = await BasketServices.createBasket(requestData);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);
export const editBasketProduct = createAsyncThunk('basket/editBasket', async (requestData, { rejectWithValue }) => {
  try {
    const { data } = await BasketServices.editBasket(requestData);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const deleteBasket = createAsyncThunk('baskets/deleteBasket',async (id, { rejectWithValue }) => {
    try {
      const {data} = await BasketServices.deleteBasket(id);
      return  data;  
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getOrders = createAsyncThunk('order/getOrder', async (data, { rejectWithValue }) => {
  try {
    const { data } = await OrderServices.getOrderByUser();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);
export const addCoupon = createAsyncThunk('coupon/addCoupon', async (newCouponData, { rejectWithValue }) => {
  try {
    const response = await CouponServices.addCoupon(newCouponData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);

export const getAllCoupons = createAsyncThunk('coupon/getAllCoupons', async (couponStatus, { rejectWithValue }) => {
  try {
    const response = await CouponServices.getAllCoupons(couponStatus);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);

export const getCarts = createAsyncThunk('cart/getCart', async (data, { rejectWithValue }) => {
  try {
    const { data } = await CartServices.getCart();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);

export const rateToBaskets = createAsyncThunk('/basket/rateToBaskets', async (requestData, { rejectWithValue }) => {
  try {
    const { data } = await BasketServices.rateToBaskets(requestData);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);

export const getAllFAQs = createAsyncThunk('faq/getAllFAQs', async (params, { rejectWithValue }) => {
  try {
    const { data } = await FAQServices.getAllFAQs(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getFAQById = createAsyncThunk('faq/getFAQById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await FAQServices.getFAQById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const getAllMemberships = createAsyncThunk('membership/getAllMemberships', async (params, { rejectWithValue }) => {
  try {
    const { data } = await MembershipServices.getMembershipPlans(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getMembershipById = createAsyncThunk('membership/getMembershipById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await MembershipServices.getMembershipById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const buyMembership = createAsyncThunk('membership/buyMembership', async (planId, { rejectWithValue }) => {
  try {
    const { data } = await MembershipServices.buyMembership(planId);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);
export const cancelMembership = createAsyncThunk('memberships/cancel',async (planId, { rejectWithValue }) => {
    try {
      const response = await MembershipServices.cancelMembership(planId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const getAllBanners = createAsyncThunk('banner/getAllBanners', async (params, { rejectWithValue }) => {
  try {
    const response = await BannerServices.getBanners(params);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export const getBannersById = createAsyncThunk('banner/getMembershipById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await BannerServices.getBannersById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getAllTestimonials = createAsyncThunk('testimonial/getAllTestimonials', async (params, { rejectWithValue }) => {
  try {
    const { data } = await TestimonialService.getAllTestimonials(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getTestimonialById = createAsyncThunk('banner/getMembershipById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await TestimonialService.getTestimonialById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const getAllAboutUs = createAsyncThunk('aboutUs/getAllAboutUs', async (params, { rejectWithValue }) => {
  try {
    const { data } = await AboutUsService.getAllAboutUs(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);
export const getAboutUsById = createAsyncThunk('aboutUs/getAboutUsById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await TestimonialService.getAboutUsById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const getAllBlog = createAsyncThunk('blogs/getAllBlog', async (params, { rejectWithValue }) => {
  try {
    const { data } = await BlogServices.getAllBlog(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);
export const getBlogById = createAsyncThunk('blogs/getBlogById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await BlogServices.getBlogById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const getAllProductOrBasketBlog = createAsyncThunk('/blog/getBlog', async (params, { rejectWithValue }) => {
  try {
    const { data } = await BlogServices.getAllProductOrBasketBlog(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getAllFarmers = createAsyncThunk('farmer/getAllFarmers', async (params, { rejectWithValue }) => {
  try {
    const { data } = await OurFarmersService.getAllFarmers(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);
export const getFarmerById = createAsyncThunk('farmer/getFarmerById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await OurFarmersService.getFarmerById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const rateToProducts = createAsyncThunk('/product/rateToProducts', async (requestData, { rejectWithValue }) => {
  try {
    const { data } = await ProductServices.rateToProducts(requestData);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);
export const getAllOurTeam = createAsyncThunk('farmer/getAllFarmers', async (params, { rejectWithValue }) => {
  try {
    const { data } = await OurTeamService.getAllOurTeam(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);
export const sendContactMessage = createAsyncThunk('contact/sendMessage', async (messageData, { rejectWithValue }) => {
  try {
    const response = await ContactService.sendContactMessage(messageData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);



export const getAllAds = createAsyncThunk('banner/getAllAds', async (params, { rejectWithValue }) => {
  try {
    const { data } = await BannerServices.getAds();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


export const getAllContactInfo = createAsyncThunk('contactinfo/getAllContactInfo', async (params, { rejectWithValue }) => {
  try {
    const { data } = await ContactService.getAllContactInfo(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getAllProductQuality = createAsyncThunk('productQuality/getAllProductQuality', async (params, { rejectWithValue }) => {
  try {
    const data = await ProductQualityService.getAllProductQuality(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);

export const getProductQualityById = createAsyncThunk('productQuality/getProductQualityById', async (id, { rejectWithValue }) => {
  try {
    const data = await ProductQualityService.getProductQualityById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);

export const getAllMarquee = createAsyncThunk('marquee/getAllMarquee', async (params, { rejectWithValue }) => {
  try {
    const data = await MarqueeService.getAllMarquee(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);

export const getMarqueeById = createAsyncThunk('marquee/getMarqueeById', async (id, { rejectWithValue }) => {
  try {
    const data = await MarqueeService.getMarqueeById(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);

export const getAllBlogCategory = createAsyncThunk('blogCategory/getAllBlogCategory', async (params, { rejectWithValue }) => {
  try {
    const { data } = await BlogServices.getAllBlogCategory(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);

export const updateCustomerProfileImage = createAsyncThunk('profile/updateCustomerProfileImage', async (data, { rejectWithValue }) => {
  try {
    const response = await EditProfileImageService.updateCustomerProfileImage(data);
    return response.data; // Assuming the response contains data
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);

export const uploadFiles = createAsyncThunk('uploadfiles/uploadFiles', async (data, { rejectWithValue }) => {
  try {
    const response = await UploadfilesService.uploadfiles(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);
export const getSearchProdutsOrBaskets = createAsyncThunk('Search/ProdutsOrBaskets', async (params, { rejectWithValue }) => {
  try {
    const { data } = await SearchProdutsOrBaskets.getSearchProdutsOrBaskets(params);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
);

export const getMostPurchased = createAsyncThunk('mostPurchased/getMostPurchased', async (_, { rejectWithValue }) => {
  try {
    const { data } = await MostPurchasedService.getMostPurchased();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});




























