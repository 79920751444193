import { useTheme } from "@mui/material";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";

const formatBreadcrumbText = (text, id) => {
  if (id) {
    return id === 'Products' ? 'Product Detail' :
      id === 'Baskets' ? 'Basket Detail' :
        id === 'productReview' ? "Product Detail" :
          id === 'basketReview' ? "Basket Detail" :
            id === 'order' ? 'Order Detail' :
              id === 'Blogs' ? 'Blog Detail' :
                id === 'Basket_Details' ? 'Basket Details' :
                  // New condition for Blog
                  ''; // Default case if none of the conditions are met

  }
  switch (text.toLowerCase()) {
    case 'profile':
      return 'My Account';
    case 'contactus':
      return 'Contact us';
    case 'about':
      return 'About Us';
    case 'ourfarms':
      return 'Our Farmers';
    case 'orderdetail':
      return 'Order Detail';
    case 'membershipsuccessful':
      return 'Membership';
    case 'cart':
      return 'Cart';
    case 'privacy-policy':
      return 'Privacy Policy';
    case 'refund-policy':
      return 'Refund Policy';
    case 'shipping-policy':
      return 'Shipping Policy';
    case 'terms-service':
      return 'Terms Service';
    case 'return-policy':
      return 'Return Policy';
    case 'Blogs':
      return 'All Blogs';
    default:
      return text;
  }
}

function BreadCumbs() {
  const currentPathName = useLocation().pathname;
  const params = useParams()
  const theme = useTheme();

  // const navigate = useNavigate();

  const currentPathname2 = currentPathName.split("/").filter((x) => x);

  return (
    <div>
      {currentPathname2.length > 0 ? (
        <Link
          to="/"
          style={{ textDecoration: "none", color: theme.palette.common.white }}
        >
          Home &#10095;{" "}
        </Link>
      ) : (
        <span>Home </span>
      )}
      {currentPathname2.map((ele, index) => {
        const routeTo = `/${currentPathname2.slice(0, index + 1).join("/")}`;

        const IsLast = index === currentPathname2.length - 1;

        return IsLast ? (
          <span>{formatBreadcrumbText(ele, params.id ? currentPathname2[currentPathname2.length - 3] === 'Baskets' || currentPathname2[currentPathname2.length - 3] === 'Products' || currentPathname2[currentPathname2.length - 3] === 'Basket_Details' ? currentPathname2[currentPathname2.length - 3] : currentPathname2[currentPathname2.length - 2] : null)}</span>
        ) : params.id === ele ? null : (
          <Link
            to={ele === 'productReview' ? '/Products' : ele === 'basketReview' ? '/Baskets' : ele === 'Basket_Details' ? "" : ele === 'order' ? '/profile' : `${routeTo}`}
            style={{
              textDecoration: "none",
              color: theme.palette.common.white,
            }}
          >
            {ele === 'profile' ? 'My Account' : ele === 'productReview' ? 'Products' : ele === 'basketReview' ? 'Baskets' : ele === 'Basket_Details' ? 'Basket Detail' : ele} &#10095;{" "}
          </Link>
        );
      })}
    </div>
  );
}

export default BreadCumbs;
