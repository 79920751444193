import { createSlice } from "@reduxjs/toolkit";
import { getAllBlog, getBlogById,getAllProductOrBasketBlog, getAllBlogCategory } from "../actions";

const initialState = {
  blogs: [],
  blogDetail: { loading: false, error: null, blog: null }, // Initialize blogDetail with loading, error, and blog properties
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const BlogSlice = createSlice({
  name: "blogs",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllBlog.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBlog.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blogs = payload;
      })
      .addCase(getAllBlog.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
      .addCase(getAllProductOrBasketBlog.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProductOrBasketBlog.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blogs = payload;
      })
      .addCase(getAllProductOrBasketBlog.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
      .addCase(getBlogById.pending, (state) => {
        state.blogDetail.loading = true;  
      })
      .addCase(getBlogById.fulfilled, (state, { payload }) => {
        state.blogDetail.loading = false; 
        state.blogDetail.blog = payload;  
      })
      .addCase(getBlogById.rejected, (state, { payload }) => {
        state.blogDetail.loading = false;  
        state.blogDetail.error = payload;  
      })
      .addCase(getAllBlogCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBlogCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blogs = payload;
      })
      .addCase(getAllBlogCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
  },
});

export default BlogSlice.reducer;
