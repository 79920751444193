import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
    FormControl,
    TextField,
    Button,
    Typography,
    Box,
    useTheme,
    MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import { appStrings } from "../../../Helper/Constant";
import { makeStyles } from "@mui/styles";
import AuthServices from "../../../services/AuthService";

const validationSchema = Yup.object().shape({
    countryCode: Yup.string().required("Country code is required"),
    name: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, "Full name can only contain letters and spaces")
        .required("Full name is required"),
    phone: Yup.string().required("Mobile number is required"),
    email: Yup.string()
        .required("Email is required")
        .matches(
            /^[a-zA-Z0-9._-]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
            "Invalid email address"
        )
        .email("Invalid email address"),
});

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        background: theme.palette.grey[500],
        border: `1px solid red`,
    },
    customTextField: {
        borderRadius: "45px",
        border: "none",
        outline: "none",
    },
    SubmitButton: {
        borderRadius: "45px",
    },
}));

const SignUpForm = ({ mobileNumber, setCounter, setUserData }) => {
    const theme = useTheme();
    const classes = useStyles();

    const handleSubmit = (values, actions) => {
        const { countryCode, ...payload } = values;

        AuthServices.customerSignUp(payload).then((res) => {
            if (res.data && res.data.data) {
                setUserData(res.data.data);
                setCounter(1);
            } else {
                if (res.data && res.data.error) {
                    actions.setFieldError('email', res.data.error);
                }
                setCounter(2);
            }
        }).catch((err) => {
            if (err.response && err.response.data && err.response.data.message) {
                actions.setFieldError('email', err.response.data.message);
            }
        });

        actions.setSubmitting(false);
    };

    const initialValues = {
        countryCode: "+91",
        phone: mobileNumber,
        name: "",
        email: "",
    };

    return (
        <div>
            <Typography variant="h3">{appStrings?.Signup}</Typography>
            <Typography variant="body1" color={"gray"} pb={4}>
                {appStrings?.signupDesc}
            </Typography>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {({
                    errors,
                    touched,
                    isSubmitting,
                    handleChange,
                }) => (
                    <Form>
                        <Box pb={1}>
                            <Field
                                as={TextField}
                                name="name"
                                id="name"
                                size="small"
                                placeholder={appStrings?.full_Name}
                                onChange={handleChange}
                                inputProps={{ className: classes?.InputStyle }}
                                variant="outlined"
                                error={errors.name && touched.name}
                                helperText={<ErrorMessage name="name" />}
                                fullWidth
                                className={classes.customTextField}
                            />
                        </Box>

                        <Box pb={1}>
                            <Field
                                as={TextField}
                                name="email"
                                id="email"
                                size="small"
                                placeholder={appStrings?.email_ID}
                                onChange={handleChange}
                                variant="outlined"
                                error={errors.email && touched.email}
                                helperText={<ErrorMessage name="email" />}
                                fullWidth
                                className={classes.customTextField}
                            />
                        </Box>

                        <Box
                            display={"flex"}
                            borderRadius={1}
                            sx={{
                                background: theme.palette.grey[1100],
                                border: "none",
                                outline: "none",
                            }}
                        >
                            <FormControl sx={{ width: "70px" }}>
                                <Field
                                    as={TextField}
                                    size="small"
                                    id="countryCode"
                                    name="countryCode"
                                    disabled
                                >
                                    <MenuItem key={1} value={"+91"} selected>
                                        +91
                                    </MenuItem>
                                </Field>
                            </FormControl>
                            <FormControl sx={{ width: "100%" }}>
                                <Field
                                    size="small"
                                    as={TextField}
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={mobileNumber}
                                    disabled
                                    className={classes.customTextField}
                                />
                            </FormControl>
                        </Box>
                        <Box py={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                                fullWidth
                                className={classes.SubmitButton}
                            >
                                Sign Up
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SignUpForm;
