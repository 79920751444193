import requests from "../Axios/Instance";

const CategoryServices = {
  getAllCategory: async () => {
    // return requests.get("/category/get/categories");
    return requests.get("/category/show");
  },
  getAllHomeCategory: async () => {
    return requests.get("/category/get/homePageCategories");
  },
  getProductByCategoryId: async (id) => {
    return requests.get(`/category/get/category/?categoryId=${id}`);
  }
};

export default CategoryServices;
