import React from "react";
import { Typography } from "@mui/material";

const BlogDescription = ({ description }) => {

    const styles = {
        p: {
            marginBottom: "0",
            lineHeight: "1.1",
            "& > p": {
                margin: "0.3rem",
            },
        },
        ul: {
            paddingInlineStart: "70px",
        },
        ol: {
            paddingInlineStart: "70px",
        },
        strong: {
            color: 'rgba(41, 41, 41, 1)',
        },
        imgContainer: {
            textAlign: "center",
        },
        img: {
            maxHeight: "20px",  
            maxWidth: "20%",  
        },
    };

    const renderHtmlContent = () => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(description, "text/html");

        return Array.from(htmlDoc.body.childNodes).map((node, index) => {
            switch (node.nodeName.toLowerCase()) {
                case "p":
                case "ul":
                case "ol":
                    return (
                        <Typography
                            key={index}
                            component={node.nodeName.toLowerCase()}
                            color="gray"
                            sx={styles[node.nodeName.toLowerCase()]}
                            dangerouslySetInnerHTML={{ __html: node.outerHTML }}
                        />
                    );
                case "img":
                    return (
                        <div key={index} style={styles.imgContainer}>
                            <img
                                src={node.getAttribute("src")}
                                alt={node.getAttribute("alt")}
                                style={styles.img}
                            />
                        </div>
                    );
                default:
                    return (
                        <Typography
                            key={index}
                            color={node.nodeName.toLowerCase() === 'strong' ? '#292929' : 'gray'}
                            dangerouslySetInnerHTML={{ __html: node.outerHTML }}
                        />
                    );
            }
        });
    };

    return <>{renderHtmlContent()}</>;
};

export default BlogDescription;
