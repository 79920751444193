import { createSlice } from "@reduxjs/toolkit";
import { getMostPurchased } from "../actions";

const initialState = {
  mostPurchased: [],
  loading: false,
  error: null,
};

const MostPurchasedSlice = createSlice({
  name: "mostPurchased",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getMostPurchased.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMostPurchased.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.mostPurchased = payload;
      })
      .addCase(getMostPurchased.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export default MostPurchasedSlice.reducer;
