import { createSlice } from "@reduxjs/toolkit";
import { uploadFiles } from "../actions"; // Import the async thunk action

const initialState = {
    uploadfiles: [],
    uploading: false,
    error: null,
};

const UploadfilesSlice = createSlice({
    name: 'uploadfiles',
    initialState,
    reducers: {},  
    extraReducers: (builder) => {
        builder
            .addCase(uploadFiles.pending, (state) => {
                state.uploading = true;
                state.error = null;
            })
            .addCase(uploadFiles.fulfilled, (state) => {
                state.uploading = false;
            })
            .addCase(uploadFiles.rejected, (state, action) => {
                state.uploading = false;
                state.error = action.payload;
            });
    },
});

export default UploadfilesSlice.reducer;  