import requests from "../Axios/Instance";

const OrderServices = {
  createOrder: async (body) => {
    return requests.post("/order/add", body);
  },
  getOrderByUser: async () => {
    return requests.get("/order");
  },
  getOrderById: async (id) => {
    return requests.get(`/order/${id}`);
  },
  cancelOrder: async (id, body) => {
    return requests.post(`/order/cancel/${id}`, body);
  },
  returnOrder: async (id, body) => {
    return requests.post(`/order/return/order/${id}`, body);
  },
  getInfoViaZipCode: async (code) => {
    return requests.get(`/zipCode/getInfoViaZipCode?zipCode=${code}`);
  },
  createCashfreePayment: async(body) => {
    return requests.post(`/order/cashfreePayment`, body);
  },
  getCashfreeOrderStatus: async (id) => {
    return requests.get(`/order/status/getCashfreeOrderStatus?order_id=${id}`);
  },
  rateToOrder: async (body) => {
    return requests.post(`/order/rateToOrder`,body);
  },
};

export default OrderServices;
