import requests from "../Axios/Instance";

const ProductQualityService = {
    getAllProductQuality: async (data) => {
        return requests.get(`/productquality/getQualities?page=${data.page}&pageSize=${data.pageSize}`);
    },
    getProductQualityById: async (id) => {
        return requests.get(`/productQuality/getById/${id}`);
    } 
};

export default ProductQualityService;
