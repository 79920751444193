import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Stack,
  MenuItem,
  Popover,
  useTheme,
  Button
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appStrings } from "../../Helper/Constant";
import { makeStyles } from "@mui/styles";
import { logOut } from "../../Store/Slice/AuthSlice";
import LazyImage from "../LazyImage";
import UserIMG from "../../Assets/Images/AccountIcon.svg";

// ----------------------------------------------------------------------

const profileMenu = [
  { id: "l1", MenuTitle: appStrings?.my_profile },
  { id: "l2", MenuTitle: appStrings?.My_Orders },
  { id: "l3", MenuTitle: appStrings?.Addresses },
  { id: "l5", MenuTitle: appStrings?.Membership },
];

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  profileMenu: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
}));

export default function AccountPopover({ profile, setProfile }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  const theme = useTheme();

  const { user } = useSelector((state) => state.AuthUser);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      onMouseLeave={handleClose} // Attach onMouseLeave to the parent Box
      sx={{
        position: 'relative', // Ensure the box is positioned correctly
      }}
    >
      <Box
        onMouseEnter={handleOpen}
        onClick={handleOpen}
        sx={{
          p: 0,
          background: "none",
          fontWeight: 600,
          display: "flex",
          flexDirection: {
            lg: 'column', // Apply flexDirection column only on large screens and up
          },
          cursor: "pointer",
          alignItems: "center",
          gap: 1,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <LazyImage
          src={
            user?.image
              ? `${process.env.REACT_APP_FILE_URL}/${user.image}`
              : UserIMG
          }
          alt="profile"
          style={{ width: "47px", height: "44px", padding: 0, borderRadius: "50%", background: '#f4f6f8' }}
        />{" "}
        {user?.name ? user.name.split(' ')[0] : ''}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0,
            },
          },
          onMouseLeave: handleClose // Attach onMouseLeave to the Popover
        }}
      >
        <Stack
          key={profileMenu.id}
          sx={{
            p: 1,
            background: '#20320D',
            color: "#fff"
          }}
        >
          {profileMenu.map((option) => (
            <MenuItem
              key={option.id}
              onClick={() => {
                navigate("/profile", { state: option });
                handleClose(); // Close the Popover when MenuItem is clicked
              }}
            >
              {option.MenuTitle}
            </MenuItem>
          ))}
          <Button
            sx={{
              color: 'primary.main',
              m: 1,
              borderRadius: '6px',
              background: 'var(--New-secondary, #FDFFB3)',
              '&:hover': {
                background: 'var(--New-secondary, #FDFFB3)', // Keep the same background color on hover
                boxShadow: 'none', // Remove the boxShadow on hover
              },
            }}
            onClick={() => {
              dispatch(logOut());
              navigate('/');
              handleClose(); // Close the Popover when Button is clicked
            }}
          >
            Logout
          </Button>
        </Stack>
      </Popover>
    </Box>
  );
}
