import requests from "../Axios/Instance";

const CartServices = {
  deleteCartById: async (id) => {
    return requests.delete(`/cart/deleteToCart?cartId=${id}`);
  },
  getCart: async () => {
    return requests.get(`/cart/getCarts`);
  },
  updateCart: async (body) => {
    return requests.put(`/cart/updateCartItem`, body);
  },
  updateStatus: async (body) => {
    return requests.put(`/cart/updateStatus`, body);
  },
  AddCart: async (body) => {
    return requests.post("/cart/addToCart", body);
  }
};

export default CartServices;
