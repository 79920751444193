import { createSlice } from "@reduxjs/toolkit";
import { getAllMemberships, getMembershipById, buyMembership, cancelMembership } from "../actions";  

const initialState = {
    memberships: [],
    membershipDetail: null,
    loading: false,
    error: null,
};

export const MembershipSlice = createSlice({
    name: "memberships",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllMemberships.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllMemberships.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.memberships = action.payload;
            })
            .addCase(getAllMemberships.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getMembershipById.pending, (state) => {
                state.loading = true;  
            })
            .addCase(getMembershipById.fulfilled, (state, { payload }) => {
                state.loading = false;  
                state.membershipDetail = payload;
            })
            .addCase(getMembershipById.rejected, (state, { payload }) => {
                state.loading = false;  
                state.error = payload;
            })
            .addCase(buyMembership.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(buyMembership.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(buyMembership.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(cancelMembership.pending, (state) => {
                state.loading = true;
                state.error = null;
              })
              .addCase(cancelMembership.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
              })
              .addCase(cancelMembership.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
              });
    },
});

export default MembershipSlice.reducer;
