import requests from "../Axios/Instance";

const AboutUsService = {
  getAllAboutUs: async (data) => {
    return requests.get(`/aboutUs/getAboutUs?type=${data.type}`);
  },
  getAboutUsById: async (id) => {
    return requests.get(`/AboutUs/${id}`);
  },
};

export default AboutUsService;
