import React from "react";
import { Navigate } from "react-router-dom";

const isAuthenticated = () => {
    return JSON.parse(localStorage.getItem("user")) ? true : false;
  };

const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/" />;
};

export default PrivateRoute;