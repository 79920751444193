// Package
import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer, Zoom } from "react-toastify";
import { Provider } from "react-redux";
//  CSS
import "./index.css";
import "./Assets/CSS/Global.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "react-step-progress-bar/styles.css";

import App from "./App";
import store from "./Store/Store";
import ThemeProvider from "./Theme/MainThemeIndex";
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <HelmetProvider>
  <ThemeProvider>
    <ToastContainer position="bottom-right" transition={Zoom} />
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
  // </HelmetProvider>
);
