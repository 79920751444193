import requests from "../Axios/Instance";

const MarqueeService = {
    getAllMarquee: async (data) => {
        return requests.get(`/marquee/getMarquees?page=${data.page}&pageSize=${data.pageSize}&type=${data.type}`);
    },
    getMarqueeById: async (id) => {
        return requests.get(`/marquee/getMarqueeById?_id=${id}`);
    } 
};

export default MarqueeService;
