import { createSlice } from "@reduxjs/toolkit";
import { getAllMarquee, getMarqueeById } from "../actions";  

const initialState = {
  marquees: [],
  marqueeDetail:  null,  
  loading: false,
  error: null,
};

export const MarqueeSlice = createSlice({
  name: "marquees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMarquee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllMarquee.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.marquees = action.payload;  
      })
      .addCase(getAllMarquee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMarqueeById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMarqueeById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.marqueeDetail = payload;  
      });
  },
});

export default MarqueeSlice.reducer;
