import React, { useCallback } from "react";
import { Box, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import { appStrings } from "../../Helper/Constant";
import { FaWhatsapp } from 'react-icons/fa';
import { FiMail } from "react-icons/fi";
import { RxDividerVertical } from "react-icons/rx";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { AiOutlineApple } from "react-icons/ai";

const SubHeader = React.memo(() => {
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleMyWhatsapp = useCallback(() => {
        const phoneNumber = "+91 9075148717";
        const defaultMessage = "Hello";

        const whatsappURL =
            "https://api.whatsapp.com/send?phone=" +
            phoneNumber +
            "&text=" +
            encodeURIComponent(defaultMessage);

        window.open(whatsappURL);
    }, []);
    const handlePlayStore = () => {
        const playStoreURL = "https://play.google.com";
        window.open(playStoreURL, "_blank");
    };

    const handleAppStore = () => {
        const appStoreURL = "https://apple.com";
        window.open(appStoreURL, "_blank");
    };

    const handleMyEmail = useCallback(() => {
        const emailAddress = "hello@lushful.org";
        const emailSubject = "Your subject here";
        const emailBody = "Your message here";

        const mailtoURL = `mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
        window.location.href = mailtoURL;
    }, []);

    return (
        <Box
            sx={{
                background: theme.palette.primary.main,

            }}
        >
            <Container sx={{
                display: "flex",
                color: "#fff",
                flexDirection: { lg: "row", xs: "row" },
                p: 1,
                justifyContent: "space-between",
            }}>

                <Typography variant="h55" sx={{
                    fontSize: {
                        xs: '9px',
                        sm: '10px',
                        md: '0.9375rem',
                        lg: '0.9375rem',
                        xl: '0.9375rem',
                    },
                }} > {appStrings?.welcome_store} </Typography>
                <Box
                    sx={{
                        display: "flex",
                        color: "#fff",
                        flexDirection: { xs: "column", lg: "row" },
                        gap: { xs: "0px", lg: 4 },
                        justifyContent: "space-between",
                        marginLeft:{ xs:"22px"},
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: theme.spacing(2),
                        }}
                    >
                        <Typography
                            variant="h55"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: theme.spacing(1),
                                cursor: "pointer",
                                fontSize: {
                                    xs: '7px',  
                                    sm: '10px',  
                                    md: '0.8125rem',  
                                    lg: '0.8125rem',  
                                    xl: '0.8125rem', 
                                },

                            }}
                            onClick={handleMyEmail}
                        >
                            <FiMail />
                            <span>hello@lushful.org</span>
                        </Typography>

                        <RxDividerVertical
                            sx={{
                                height: "40px",
                                mb: { xs: 2, lg: 0 },
                            }}
                        />


                        <Typography
                            variant="h55"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: theme.spacing(1),
                                cursor: "pointer",
                                fontSize: {
                                    xs: '7px',  
                                    sm: '10px',  
                                    md: '0.8125rem', 
                                    lg: '0.8125rem', 
                                    xl: '0.8125rem', 
                                },
                            }}
                            onClick={handleMyWhatsapp}
                        >
                            <FaWhatsapp /> <span>+91 80733 84821</span>
                        </Typography>


                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        color: "#fff",
                        flexDirection: { xs: "column", lg: "row" },
                        gap: { xs: 4, lg: 4 },
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: theme.spacing(2),
                        }}
                    >
                        {!isMobileScreen && (
                            <Typography
                                variant="h55"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: theme.spacing(1),
                                    cursor: "pointer",

                                }}
                                onClick={handlePlayStore}
                            >
                                <IoLogoGooglePlaystore />
                                <span>Play Store</span>
                            </Typography>
                        )}
                        {!isMobileScreen && (
                            <RxDividerVertical
                                sx={{
                                    height: "40px",
                                    mb: { xs: 2, lg: 0 },
                                }}
                            />
                        )}
                        {!isMobileScreen && (
                            <Typography
                                variant="h55"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: theme.spacing(1),
                                    cursor: "pointer",

                                }}
                                onClick={handleAppStore}
                            >
                                <AiOutlineApple /><span>App Store</span>
                            </Typography>

                        )}
                    </Box>
                </Box>
            </Container>
        </Box >
    );
});

export default SubHeader;
