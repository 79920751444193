import { createSlice } from "@reduxjs/toolkit";
import { getAllFAQs, getFAQById } from "../actions";

const initialState = {
  faqs: [],
  faqDetail: null,
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
};

export const FAQSlice = createSlice({
  name: "faqs",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllFAQs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFAQs.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;     
        state.faqs = payload;
      })
      .addCase(getAllFAQs.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      })
      .addCase(getFAQById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFAQById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.faqDetail = payload;
      })
  },
});
export default FAQSlice.reducer;
