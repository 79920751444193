import requests from "../Axios/Instance";

const AuthServices = {
  getGlobalSettings: async () => {
    return requests.get("setting/global/all")
  },
  customerSignUp: async (data) => {
    return requests.post(`customer/signUpByPhone`, data);
  },
  customerSignIn: async (data) => {
    return requests.post(`customer/signInByPhone`, data);
  },
  customerVerifyOtp: async (data) => {
    return requests.post(`customer/verify/otp`, data);
  },
  getCustomerById: async (id) => {
    return requests.get(`customer/${id}`);
  },
  getCustomer: async (id) => {
    return requests.get(`customer/profile`);
  },
  updateCustomerById: async (id,data) => {
    return requests.put(`customer/${id}`,data);
  },
  updateCustomerProfileImage: async (data,id) => {
    return requests.put(`/customer/updateCustomerProfileImage/${id}`, data);
},
  addCustomerAddress: async (data) => {
    return requests.put(`customer/add/newAdresses`,data);
  },
  updateCustomerAddress: async (data) => {
    return requests.put(`customer/update/address`,data);
  },
  deleteCustomerAddress: async (data) => {
    return requests.put(`customer/delete/address`,data);
  },
  CustomerDefaultAddress: async (data) => {
    return requests.post(`customer/makeAddressDefault`, data);
  },
  uploadfiles: async (data) => {
    return requests.post(`/uploadfiles`, data);
  }
};

export default AuthServices;
