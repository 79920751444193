import React, { useState, useEffect } from "react";
import {
  Box,
  Select,
  MenuItem,
  List,
  ListItem,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import CloseImg from "../../Assets/Images/SuccessfullMsg/close 1.png";
import ListNotFoundImg from "../../Assets/Images/other/empty- 1.png";
import NoImage from "../../Assets/Images/Products.png";
import { useSelector, useDispatch } from "react-redux";
import { getCarts, getProductList, getSearchProdutsOrBaskets } from "../../Store/actions";
import { toast } from "react-toastify";
import CartServices from "../../services/CartService";
import LazyImage from "../LazyImage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",
    gap: "20px",
  },
  InputCardBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.grey[200],
    height: 45,
    width: "100%",
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  closeIcon: {
    position: "absolute",
    right: "-10px",
    top: "-20px",
  },
  SelectBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#3f2813",
    height: 45,
    // width: {xs:"178px !important",lg:"179px !important",}
  },
  serchNAme: {
    color: theme.palette.primary.dark,
  },
}));

function HeaderSearchBar() {
  const customStyles = `
  #selectCategory > .selectCategory{
    width: 175px !important;
    background: #3f2813 !important;
    color: #fff !important;
  }

  #selectCategory > .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-sizeSmall.css-rvrakz{
    width: 100% !important;
    background: #3f2813 !important;
    color: #fff !important;
  }

  .selectCategory > .MuiSelect-icon.MuiSelect-iconOutlined {
    color: #fff !important;
  }
  `;
  const [input, setInput] = useState("");
  const theme = useTheme();
  const [list, setList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [category, setCategory] = useState("0");
  const currentPathName = useLocation().pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.products);
  const categoryData = useSelector((state) => state.category.data);
  const cartData = useSelector((state) => state.carts);
  const [selectedCategoryName, setSelectedCategoryName] = useState("Select Category");
  const { search } = useSelector((state) => state.search);

  useEffect(() => {
    dispatch(getProductList());
  }, [dispatch]);

  useEffect(() => {
    if (currentPathName === "/" || !currentPathName.includes("/Products")) {
      setCategory("0");
      setSelectedCategoryName("Select Category");
    }
  }, [currentPathName]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      filterList();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [input, search, data]);

  const filterList = () => {
    let filteredList = data;

    if (input) {
      filteredList = data.filter((item) =>
        item.title.en.toLowerCase().includes(input.toLowerCase()) ||
        item.tag.toLowerCase().includes(input.toLowerCase()) ||
        item.description.en.toLowerCase().includes(input.toLowerCase())
      );
    }

    setList(filteredList);
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        navigate("/Products/All", {
            state: { 
                id: 101, 
                name: { en: "All" },
                searchTerm: input 
            },
        });
        handleClose(); // Automatically close the search results
    }
};


  const [counts, setCounts] = useState([]);
  useEffect(() => {
    setFilteredItems(list);
    setCounts(new Array(list?.length).fill(1));
  }, [list]);

  const handleChange = (value) => {
    setInput(value);

    // Trigger search API call
    const obj = {
      type: "All",
      subType: "Predefined",
      string: value
    };
    dispatch(getSearchProdutsOrBaskets(obj));
  };

  // const handleClose = () => {
  //   setInput("");
  //   setList([]);
  // };
  const handleIncrement = (index) => {
    const newCounts = [...counts];
    newCounts[index] += 1;
    setCounts(newCounts);
  };

  const handleDecrement = (index) => {
    const newCounts = [...counts];
    newCounts[index] -= 1;
    if (newCounts[index] < 1) newCounts[index] = 1;
    setCounts(newCounts);
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory._id);
    setSelectedCategoryName(selectedCategory.name.en);
    navigate(`/Products/${selectedCategory.name.en}`, {
      state: selectedCategory,
    });
  };

  const handleClose = () => {
    setInput("");
    setFilteredItems([]);
  };

  const insertCart = (prod, count) => {
    const obj = {
      title: prod.title.en,
      productOrBasketId: prod._id,
      type: "Product",
      sku: `${prod.variants[0].weight}${prod.variants[0].weightType}`,
      variantId: prod.variants[0]._id,
      originalPrice: prod.variants[0].prices?.originalPrice,
      discount: prod.variants[0].prices.discount,
      quantity: count,
      images: prod.image,
    };

    const existingItem = cartData.data.find(
      (c) => c.productOrBasketId === prod._id && c.sku === obj.sku
    );

    if (existingItem) {
      toast.warning("Already added in cart", {
        position: "top-right",
        theme: "colored",
      });
    } else {
      CartServices.AddCart(obj)
        .then(() => {
          dispatch(getCarts());
          toast.success("Item added successfully", {
            position: "top-right",
            theme: "colored",
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.status === 401) {
            toast("Please first login", {
              position: "top-center",
              type: "error",
              theme: "colored",
            });
          } else {
            toast("Something went wrong", {
              position: "top-center",
              type: "error",
              theme: "colored",
            });
          }
        });
    }
  };

  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      sx={{
        px: { lg: 2 },
        width: { xs: "100%", lg: "45%", },
        marginLeft: { lg: "-130px" },
        // position: "absolute !important ",
        // left: "200px",
      }}
    >
      <style dangerouslySetInnerHTML={{ __html: customStyles }} />
      <Box
        className={classes.InputCardBox}
        sx={{
          borderRadius: "6px",
          px: 2,
          py: 1,
          mt: { xs: 2, sm: 2, lg: "0" },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <input
            placeholder="Search here..."
            value={input}
            style={{
              width: "100%",
              padding: "12px 10px",
              borderRadius: "8px 0 0 8px",
              border: "none",
              outline: "none",
              background: "#f4f6f8",
              color: "var(--Foundation-black-tints-black-tints-200, #525252)",
              fontFamily: "Phenomena",
              fontSize: "17px",
              fontWeight: 400,
              height: "34px",
            }}
            onChange={(e) => handleChange(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Box>
      <Box
        id="selectCategory"
        className={classes.SelectBtn}
        sx={{
          borderRadius: "6px",
          px: 2,
          py: 1,
          mt: { xs: 2, sm: 2, lg: "0" },
          width: "178px"
        }}
      >
        <Select
          aria-label="select category"
          className="selectCategory"
          value={category}
          displayEmpty
          onChange={handleCategoryChange}
          sx={{
            border: "none",
            width: "37% ",
            color: "#757575",
            "& fieldset": {
              border: category ? "none" : undefined,
            },
          }}
          size="small"
          renderValue={() => selectedCategoryName}
        >
          <MenuItem value="0" hidden>
            Select Category
          </MenuItem>
          {categoryData?.data?.map((c) => (
            <MenuItem key={c._id} value={c}>
              {c.name.en}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {input?.length > 0 && (
        <Box
          sx={{
            position: "absolute",
            top: "65%",
            left: "1%",
            zIndex: "1000",
            width: "99%",
            backgroundColor: "white",
            display: "flex",
            padding: "18px",
            flexDirection: "column",
            boxShadow: "0px 0px 8px #ddd",
            borderRadius: "10px",
            marginTop: "1rem",
            maxHeight: "300px",
            overflowY: "auto",
            "@media (max-width: 600px)": {
              width: "100%",
              top: "50%",
            },
          }}
        >
          {filteredItems?.length === 0 ? (
            <Box>
              <Box position="relative">
                <img
                  src={CloseImg}
                  alt="CloseImg"
                  height="22px"
                  width="22px"
                  align="right"
                  className={classes.closeIcon}
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              <Box className={classes.emptyContainer}>
                <img
                  src={ListNotFoundImg}
                  alt="empty"
                  style={{
                    width: "150px",
                    height: "150px",
                  }}
                />
                <Typography color="gray">
                  No data found{" "}
                  <span className={classes.serchNAme}>{input}</span>
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              <Typography>
                Showing results for <span> {input}</span>
                <Box position="relative">
                  <img
                    src={CloseImg}
                    alt="CloseImg"
                    height="22px"
                    width="22px"
                    align="right"
                    className={classes.closeIcon}
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Typography>
              <List border={1} borderColor="gray">
                {filteredItems?.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: { xs: "column", sm: "row", md: "row" },
                       
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                      width={"212px"}
                      onClick={() => {
                        navigate(`/Products/${item._id}/${item.title.en}`);
                        setFilteredItems([]);
                        setInput("");
                      }}
                    >
                      <LazyImage
                        src={
                          item.image?.length
                            ? `${process.env.REACT_APP_FILE_URL}/${item.image[0]}`
                            : NoImage
                        }
                        alt="he"
                        style={{
                          width: "50px",
                          height: "50px",
                          background: "gray",
                        }}
                      />
                      <Box>
                        <Typography variant="h5" fontSize={"15px"}>
                          {item.title.en}{" "}
                          <span
                            style={{
                              borderRadius: 800,
                              background: "#FDFFB3",
                              color: "#000",
                            }}
                          >
                            {item.variants[0]?.weight}
                            {item.variants[0]?.weightType}
                          </span>
                        </Typography>
                        <Typography>
                          {item.variants[0]?.prices?.price.toFixed(2) || 0}{" "}
                          <span className="discountMidlleLine">
                            {item.variants[0]?.prices?.originalPrice.toFixed(
                              2
                            ) || 0}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={"incrementButtonsGruoup"}>
                      <Button
                        className="gray_button"
                        onClick={() => handleDecrement(index)}
                      >
                        -
                      </Button>
                      <Typography px={2}>{counts[index]}</Typography>
                      <Button
                        className="gray_button"
                        onClick={() => handleIncrement(index)}
                      >
                        +
                      </Button>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} gap={2} >
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{
                          background: "#fff",
                          color: theme?.palette?.primary.main,
                          border: `1px solid ${theme?.palette?.primary.main}`,
                          borderRadius: "4px",
                          px: 2,
                          minWidth: "65px !important",
                          '&:hover': {
                            background: theme?.palette?.primary.main,
                            color: "#fff",
                          },
                        }}
                        onClick={() => insertCart(item, counts[index])}
                      >
                        ADD
                      </Button>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default HeaderSearchBar;
