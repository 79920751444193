import { configureStore } from "@reduxjs/toolkit";
import AuthSliceReducer from "./Slice/AuthSlice";
import CategorySliceReducer from "./Slice/CategorySlice";
import ProductSliceReducer from "./Slice/ProductSlice";
import BasketSliceReducer from "./Slice/BasketSlice";
import CartSliceReducer from "./Slice/CartSlice";
import OrderSliceReducer from "./Slice/OrderSlice";
import CouponSliceReducer from "./Slice/CouponSlice";
import FAQSliceReducer from "./Slice/FAQSlice";
import MembershipSliceReducer from "./Slice/MembershipSlice";
import BannerSliceReducer from "./Slice/BannerSlice";
import TestimonialSliceReducer from "./Slice/TestimonialSlice";
import AboutUsSliceReducer from "./Slice/AboutUsSlice";
import BlogSliceReducer from "./Slice/BlogSlice";
import OurFarmersSliceReducer from "./Slice/OurFarmersSlice";
import OurTeamSliceReducer from "./Slice/OurTeamSlice";
import ContactUsSliceReducer from "./Slice/ContactUsSlice";
import ProductQualitySliceReducer from "./Slice/ProductQualitySlice";
import MarqueeSliceReducer from "./Slice/MarqueeSlice";
import BlogCategorySliceReducer from "./Slice/BlogCategorySlice";
import EditProfileImageSliceReducer from "./Slice/EditProfileImageSlice";
import UploadfilesSliceReducer from "./Slice/UploadfilesSlice";
import SearchProdutsOrBasketsSliceReducer from "./Slice/SearchProdutsOrBasketsSlice ";
import MostPurchasedSliceSliceReducer from "./Slice/MostPurchasedSlice";

// const middleware = getDefaultMiddleware({
//   serializableCheck: false,
// });

const rootReducer = {
  AuthUser: AuthSliceReducer,
  category: CategorySliceReducer,
  products: ProductSliceReducer,
  baskets: BasketSliceReducer,
  carts: CartSliceReducer,
  orders: OrderSliceReducer,
  coupons: CouponSliceReducer,
  faqs: FAQSliceReducer,
  memberships: MembershipSliceReducer,
  banners: BannerSliceReducer,
  testimonials: TestimonialSliceReducer,
  aboutUs: AboutUsSliceReducer,
  blogs: BlogSliceReducer,
  farmers: OurFarmersSliceReducer,
  ourTeams: OurTeamSliceReducer,
  contactUs: ContactUsSliceReducer,
  productQualities: ProductQualitySliceReducer,
  marquees:MarqueeSliceReducer,
  blogCategory:BlogCategorySliceReducer,
  profileImage:EditProfileImageSliceReducer,
  uploadfiles:UploadfilesSliceReducer,
  search:SearchProdutsOrBasketsSliceReducer,
  mostPurchased:MostPurchasedSliceSliceReducer,

};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
